@import "@Assets/styles/Variables/Variable.scss";
.filter-section{
    margin: 28px 60px 0 60px;
    padding: 16px;

    @include lg-mobile{
        margin: 10px;
        padding: 10px;
    }

    .filter-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        @include lg-mobile{
            align-items: flex-start;
        }

        .f-h-heading{
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;       
            color: #667085;
        }
        .f-h-clear{
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;          
            text-align: right;           
            color: #7F56D9;
            cursor: pointer;
            text-decoration: none;
            text-wrap: nowrap;
            min-width: 80px;
            display: flex;
            svg {
                margin-right: 5px;
            }


        }
    }
    .filter-dropdown-container{
        display: flex;
        gap: 15px;

        @include lg-mobile{
            flex-wrap: wrap;
        }


        .filter-dropdown.p-dropdown:not(.p-disabled).p-focus {
            outline: 0 none;
            outline-offset: 0;
            box-shadow:none;
            border-color: #6366F1;
          }
    }
}