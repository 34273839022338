
.user-profile-sidebar-conatiner{
    padding: 50px 45px 0 60px;
    border-right: 1px solid #EAEDEF;
    height: 100vh;



    .user-prof-header{
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;        
        color: #101828;
    }
    .u-prof-side-ul{
          list-style: none;

        .side-li{
            
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;    
            color: #667085; 
            padding: 9px 14px;
            margin: 25px 0;
            display: flex;
            cursor: pointer;
        }
        .side-li:hover{
            background: #F9F5FF;
            border-radius: 8px;
            color: #7F56D9;

        }
        .side-li-svg{
            margin-right: 12px;
            display: block;
        }
        .side-li:hover  .side-li-svg {
            display: none;
            
        }
        .side-li:hover  .actve-li-svg {
            display: block;
            
        }
       .actve-li-svg{
            margin-right: 12px;
            display: none;
        }
      
        .active-li{
        background: #F9F5FF;
        border-radius: 8px;
        color: #7F56D9;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;    
        padding: 9px 14px;
        margin: 25px 0;
        display: flex;
        cursor: pointer;

        
        .actve-li-svg{
            display: block;
        }

        .side-li-svg{
            display: none;
        }

        }

       

    }
}