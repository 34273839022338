@import "@Assets/styles/Variables/Variable.scss";

.dashboard-container{
    display: flex;

    .dashboard-sidebar{
        width: 295px;
        @include lg-mobile{
            display: none;
        }

    }

    .dashboard-content-page{
        flex: 1;
    }
}