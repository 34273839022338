.Expire-time-box {

    .ex-t-head {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.2px;
        color: #101828;
        padding-bottom: 12px;

    }

    .ex-t-para {
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #101828;
        

    }

    .dialog-end-test-btn {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        background: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
        box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
        border-radius: 8px;
        color: #FFFFFF;
        padding: 10px 20px;
        width: 100%;
        margin-top: 15px;
    }
}

.custom-expire-dialog .p-dialog-header .p-dialog-header-icon{
    display: none;
}