@import "@Assets/styles/Variables/Variable.scss";

.q-n-ui-1 {
    padding: 25px 32px;

    .q-n-header {
        .q-n-h1 {
            font-weight: 600;
            font-size: 20px;
            color: #18191F;
            margin-bottom: 35px;
        }

        .q-n-h2 {
            font-weight: 500;
            font-size: 20px;
            color: #4B4B4B;

        }
    }

    .full-qust-ul {

        .full-qust-li {
            display: flex;
            align-items: flex-start;
            cursor: pointer;
            margin-bottom: 20px;

            .palette-qust {
                color: #101828;
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.64px;
                margin-left: 12px;
            }
        }

        .full-qust-palette {
            min-width: 45px;
            min-height: 45px;
            background: #FFFFFF;
            border: 1px solid #8B54FF;
            border-radius: 5px;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.06em;
            color: #8B54FF;
            display: flex;
            justify-content: center;
            align-items: center;

            @include lg-mobile {
                margin: 17px 15px;
            }

        }

        .red-flag {

            .full-qust-palette {
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    background: url(https://d3lzbbhyvqc4k.cloudfront.net/images/academy/flag-filled-test.png) no-repeat 0 0;
                    top: -11px;
                    right: -9px;
                    width: 18px;
                    height: 20px;
                    z-index: 9;
                }
            }

        }

        .active {
            .full-qust-palette {
                background: #6FCF97;
                border: 1px solid #6FCF97;
                color: #FFFFFF;
            }


        }

        .active-selected {
            .full-qust-palette {
            background: #8DE762;
            border: 1px solid #8DE762;
            color: #FFFFFF;
            }
        }

        .active-not {
            .full-qust-palette {
                background: red;
                border: 1px solid red;
                color: #FFFFFF;
            }


        }

        .active {
            .full-qust-palette {
                background: #6FCF97;
                border: 1px solid #6FCF97;
                color: #FFFFFF;
            }

        }

        .active-2 {
            .full-qust-palette {
                background: #FFD059;
                border: 1px solid #FFD059;
                color: #FFFFFF;
            }

        }

    }

    .q-n-contents {
        margin-top: 20px;

        .q-n-lists {
            list-style-type: none;
            padding: 0;
            margin: 0 -22px;
            display: flex;
            flex-wrap: wrap;

            @include lg-mobile {
                margin: 0 -15px;
            }

            li {
                width: 45px;
                height: 45px;
                background: #FFFFFF;
                border: 1px solid #8B54FF;
                border-radius: 5px;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: -0.06em;
                color: #8B54FF;
                margin: 17px 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                @include lg-mobile {
                    margin: 17px 15px;
                }

                &.red-flag {
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        background: url(https://d3lzbbhyvqc4k.cloudfront.net/images/academy/flag-filled-test.png) no-repeat 0 0;
                        top: -11px;
                        right: -9px;
                        width: 18px;
                        height: 20px;
                        z-index: 9;
                    }
                }

                &.active {
                    background: #6FCF97;
                    border: 1px solid #6FCF97;
                    color: #FFFFFF;


                }
                &.active-selected {
                    background: #8DE762;
                    border: 1px solid #8DE762;
                    color: #FFFFFF;
                    
                }

                &.active-not {
                    background: red;
                    border: 1px solid red;
                    color: #FFFFFF;

                }

                &.active {
                    background: #6FCF97;
                    border: 1px solid #6FCF97;
                    color: #FFFFFF;
                }

                &.active-2 {
                    background: #FFD059;
                    border: 1px solid #FFD059;
                    color: #FFFFFF;
                }

            }
        }
    }
}


/* */
.close-pallete-1 {
    display: none;

    @include lg-mobile {
        position: absolute;
        right: 15px;
        top: 13px;
        z-index: 3;
        display: block;
        margin-top: 10px;
    }

}