@import "@Assets/styles/Variables/Variable.scss";

.user-profile-page {
  display: flex;

  .user-profile-sidebar {
    width: 320px;

    @include lg-mobile{
      display: none;
    }
  }

  .user-profile-main-contant {
    flex: 1;
  }
}
