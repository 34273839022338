@import "./abstracts/variables";
@import "./typography/fonts";
@import "./resets/resets";
@import "./components/buttons";
@import "./abstracts/utilities";


body {
    font-family: "roboto-pt" !important;
    padding: 0;
    margin: 0;
}


.para-head {
    color: #101828;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-right: 10px;
    display: block;
}

.para-strong {
    color: #101828;
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
}

.dialog-ok-btn {
    border-radius: 8px;
    background: var(--mock-test-platform-primary-cta, linear-gradient(270deg, #986EFA 0%, #D27BB8 100%));
    box-shadow: 0px 4px 8px 0px rgba(152, 110, 250, 0.25);
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
    width: 100%;


}