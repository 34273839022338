@import "@Assets/styles/Variables/Variable.scss";

.dashboard-sidebar-container {
  padding: 3px 0 45px 0;
  background: #f4f5f7;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include lg-mobile{
    display: none;
  }

  .dashboard-ul-card {
    list-style: none;

    .navlink-li {
      padding: 13px 36px;
      display: flex;
      align-items: center;
      margin: 10px 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #7f56d9;
    }

    .navlink-li:hover {
      background: linear-gradient(360deg, #986efa 10.85%, #d27bb8 184.41%);
      color: #ffffff;
    }
    .navlink-li:hover .dashboard-svg {
      .dash-img-svg{
        display: none;
        
      }
      .active-dash-img-svg{
        display: block;
      }
    }

    .dashboard-svg {
      margin-right: 18px;

      .active-dash-img-svg{
        display: none;
      }
    }

    .active-navlink-li {
      padding: 13px 36px;
      display: flex;
      align-items: center;
      margin: 10px 0;
      color: #ffffff;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      background: linear-gradient(360deg, #986efa 10.85%, #d27bb8 184.41%);
    }

    .active-navlink-li .dashboard-svg {
      .dash-img-svg{
        display: none;
        
      }
      .active-dash-img-svg{
        display: block;
      }
    }
  }
  .logout-ul-card {
    li {
      padding-left: 34px;

      .logout-li-tag {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #7f56d9;
        cursor: pointer;

        .logout-svg {
          margin-right: 18px;
        }
      }
    }
  }
}
