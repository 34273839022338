.dashboard-mybadge-container {
  padding: 28px 185px 45px 0;

  .badge-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.06em;
    color: #101828;
    padding: 0 0 28px 45px;
  }

  .badge-cards-container {
    padding-left: 28px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .badge-card {
      background: #ffffff;
      border: 1px solid #eaecf0;
      box-shadow: 0px 4px 8px rgba(106, 106, 106, 0.15);
      border-radius: 10px;
      margin: 17px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 175px;
      cursor: pointer;

      .badge-img {
        img {
          width: 90px;
          height: 90px;
        }
      }

      .badge-status-head {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #7f56d9;
        padding-top: 12px;
      }
    }
  }
}

/*-------------------------------------------pop-up-page--------------------*/
.badge-dialog-popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  .badge-dilog-head {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: #7f56d9;
    margin-top: 20px;
  }

  .badge-dilg-para {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding: 38px 0;
    color: #101828;
  }

  .tell-us-btn {
    background: linear-gradient(270deg, #986efa 27.07%, #d27bb8 155.73%);
    box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
    border-radius: 8px;
    padding: 20px;

    width: 350px;

    .tell-us-btn-a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: #ffffff;

      .share-icon-dialog {
        margin-right: 10px;
      }
    }
  }
}
