@import "@Assets/styles/Variables/Variable.scss";

.qust-page-card {
    padding: 25px 10px 32px 32px;


    @include lg-mobile {
        padding: 15px 5px 15px 15px;
    }

    .qust-main-card {
        margin-top: 28px;
        padding-right: 42px;
        height: calc(72vh - 50px);
        overflow: auto;

        @include lg-mobile {
            padding-right: 12px;
        }

    }

    .qust-anst-card {
        display: flex;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        justify-content: space-between;
        margin-right: 35px;

        .qust-span {
            background: #FFFFFF;
            border: 1px solid #EAECF0;
            border-radius: 5px 0px 0px 5px;
            padding: 11px 20px;
            color: #7F56D9;
        }

        .ans-span {
            padding: 11px 20px;
            background: #7F56D9;
            color: #FFFFFF;
            border-radius: 0px 5px 5px 0px;
        }

        .qust-subjct {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            padding: 8px 20px;
            color: #667085;
            background: #FFFFFF;
            border: 1px solid #EAECF0;
            border-radius: 5px;
            text-transform: capitalize;
        }

    }

    .qust-marks-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #667085;
        margin-top: 25px;
        margin-right: 37px;

        .flag-svg {
            cursor: pointer;
        }

        .qust-num {
            margin-right: 17px;

        }

        .ngvt-mark-span {
            color: #7F56D9;

        }
    }

    .main-qust-card {

        .main-qust {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            color: #101828;

        }

        .qust-options-card {
            margin-top: 20px;

            .opt-div {
                display: flex;
                align-items: flex-start;
                padding: 10px 15px;
                background: #FFFFFF;
                border: 1px solid #EAECF0;
                border-radius: 8px;
                margin: 15px 0;
                cursor: pointer;

                @include lg-mobile {
                    padding: 13px;

                }

                .alpb-opt {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    color: #7F56D9;
                    padding: 5px 11px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    border: 1px solid #7F56D9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 18px;
                }

                .opt-label {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: #667085;
                }
            }

            .active-selected {
                background: #F9F5FF;
                border: 1px solid #7F56D9;

                .alpb-opt {
                    background: #7F56D9;
                    border: 1px solid #7F56D9;
                    color: #FFFFFF;
                }
            }
        }
    }

    .response-marked-card {
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .clear-resp-btn {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #7F56D9;
        padding: 11px 13px;
        border: 1px solid #7F56D9;
        border-radius: 8px;
        margin-top: 28px;
    }

    .active-btn {
        background: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
        color: #FFFFFF;
        border: 1px solid #986EFA;
    }

}


.qust-toast-card {

    .marked-span {
        font-size: 17px;
        font-weight: 600;

    }

    .marked-status {
        width: 276px;
        height: 20px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
    }
}