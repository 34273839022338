@import "@Assets/styles/Variables/Variable.scss";

.subscrp-update-mock-test {
    margin: 35px 60px 0 60px;
    background: #F9F5FF;
    border-radius: 8px;
    padding-top: 60px;
    padding-bottom: 115px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include lg-mobile {
        margin: 20px;
        padding-bottom: 10px;
    }

    .subp-left-user-cards {

        @include lg-mobile {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;
            min-height: 160px;
        }

        .subp-left-user-1 {
            img {
                margin-left: 60px;

                @include lg-mobile {
                    margin-left: 0px;
                }
            }

        }

        .subp-left-user-2 {
            @include lg-mobile {
                align-self: flex-start;
            }

            img {

                margin: 45px 0 70px 180px;

                @include lg-mobile {
                    margin: 0;

                }
            }


        }

        .subp-left-user-3 {
            img {
                margin-left: 80px;

                @include lg-mobile {
                    margin-left: 0;
                }
            }


        }
    }

    .subp-right-user-cards {
       @include lg-mobile{
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-height: 160px;
       }


        .subp-right-user-1 {


            img {
                margin-left: 80px;

                @include lg-mobile {
                    margin-left: 0;
                }
            }

        }

        .subp-right-user-2 {
            align-self: flex-end;

            img {

                margin: 70px 182px 45px 0;

                @include lg-mobile {
                    margin: 0;
                }

            }

        }

        .subp-right-user-3 {

            img {
                margin-left: 120px;

                @include lg-mobile {
                    margin-left: 0;
                }
            }

        }
    }

    .subp-update-card {
        text-align: center;
        margin-top: 20px;

        @include lg-mobile {
            margin: 35px 0;
        }

        .sub-upd-heading {
            font-weight: 700;
            font-size: 45px;
            line-height: 55px;
            color: #101828;

            @include lg-mobile {
                font-size: 25px;
                line-height: 35px;

                br {
                    display: none;
                }
            }
        }

        .sub-upd-para {
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            padding: 20px 0 40px 0;
            color: #667085;

            @include lg-mobile {
                font-size: 15px;
                padding: 10px 0;
            }
        }

        .subscribe-email-box {
            display: flex;
            align-items: center;
            background: #FFFFFF;
            border-radius: 8px 0px 0px 8px;
            height: 50px;
            width: 500px;
            margin-left: 70px;

            @include lg-mobile {
                margin-left: 0px;
                width: 100%;
                margin-bottom: 20px;
            }

            input {
                border: none;
                outline: none;
                padding-left: 20px;
                width: 100%;
            }


            .subsc-btn {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #FFFFFF;
                background: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
                border-radius: 0px 8px 8px 0px;
                height: 50px;
                padding: 13px 30px;

            }
        }
    }



}