@import "@Assets/styles/Variables/Variable.scss";

.leader-board-container {
  padding: 28px 60px 52px 45px;

  @include lg-mobile {
    padding: 15px;
  }

  .leader-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.06em;
    color: #101828;
    display: flex;
    align-items: center;
    margin-bottom: 28px;

    .back-btn-span {
      cursor: pointer;
    }

    img {
      margin-top: 10px;
      margin-right: 15px;
    }
  }

  .leader-board-cards-container {
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 4px 8px rgba(106, 106, 106, 0.15);
    border-radius: 10px;
    padding: 45px;

    @include lg-mobile {
      padding: 15px;
    }

    .l-b-3-rankers-card {
      position: relative;
      background: linear-gradient(97.25deg, #986efa 14.52%, #d27bb8 110.07%);
      border-radius: 10px;

      .bg-svg {
        position: absolute;
        top: 0;
        bottom: 0;
      }



      .first-ranker-crown {
        display: flex;
        justify-content: center;
        z-index: 999;
        margin-bottom: 6%;
        margin-left: 2%;

        img {
          margin-top: 30px;
        }
      }

      .l-b-ranker-content-card {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 50;

        @include lg-mobile {
          padding: 0 15px;
        }

        .ranker-name {
          font-weight: 600;
          font-size: 22px;
          line-height: 17px;
          color: #ffffff;
          margin-top: 12px;

          @include lg-mobile {
            font-size: 12px;
          }
        }

        .marks-scored {
          font-weight: 700;
          font-size: 20px;
          line-height: 19px;
          color: #ffffff;
          margin-top: 10px;
        }

        .second-ranker-card {
          background: #8bdef9;
          border-radius: 15px 15px 0px 0px;
          text-align: center;
          padding: 54px 26px;
          position: relative;
          height: 145px;

          @include lg-mobile {
            padding: 54px 18px;
          }

          .second-ranker-img {
            border: 3px solid #47c7ef;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);

            img {
              width: 69px;
              height: 69px;
              border-radius: 50%;
            }

            .name-initial {
              font-size: 16px;
            }
          }

          .profile-pic-bg {
            background-color: #f3eeff;
          }

          .rank-scored-second {
            position: absolute;
            top: 27.5%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .first-ranker-card {
          background: #e7a06e;
          border-radius: 15px 15px 0px 0px;
          text-align: center;
          padding: 59px 42px 79px 42px;
          position: relative;
          height: 175px;

          @include lg-mobile {
            padding: 59px 27px 79px 27px;
          }

          .first-ranker-img {
            border: 3px solid #df803d;
            width: 85px;
            height: 85px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);

            img {
              width: 79px;
              height: 79px;
              border-radius: 50%;
            }
          }

          .rank-scored-first {
            position: absolute;
            top: 25.45%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .third-ranker-card {
          background: #8bde75;
          border-radius: 15px 15px 0px 0px;
          text-align: center;
          padding: 39px 20px;
          position: relative;
          height: 120px;

          @include lg-mobile {
            padding: 34px 15px;
            height: 105px;

          }

          .third-ranker-img {
            border: 3px solid #64d247;
            width: 75px;
            height: 75px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 50%;
            right: 50%;
            transform: translate(-50%, -50%);

            img {
              width: 69px;
              height: 69px;
              border-radius: 50%;
            }
          }

          .rank-scored-third {
            position: absolute;
            top: 36.5%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    .rankers-list {
      margin-top: 35px;


      .mob-score-card {
        .mob-marks-secured {
          color: #101828;
          font-size: 14px;
          font-weight: 500;
          line-height: normal;
          padding-bottom: 8px;
          display: block;
        }

        .mob-time-rank-card {
          display: flex;
          color: #667085;
          font-size: 10px;
          font-weight: 500;
          line-height: normal;
        }
      }

      .rankers-head-list {
        background: #a276fe;

        th {
          font-weight: 600;
          font-size: 20px;
          line-height: 22px;
          color: #ffffff;
          text-align: center;
          padding: 22px;

          @include lg-mobile {
            font-size: 15px;
            padding: 10px;
          }
        }

        th:first-child {
          border-top-left-radius: 10px;
        }

        th:last-child {
          border-top-right-radius: 10px;
        }
      }

      .rankers-list {
        border: 1px solid #dee2e6;

        td {
          font-weight: 500;
          font-size: 20px;
          line-height: 17px;
          text-align: center;
          color: #18191f;
          padding: 15px 24px;

          @include lg-mobile {
            padding: 15px;
            color: #101828;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
          }

          .ranker-medal-svg {
            margin-right: -30px;

            img {
              width: 50px;
              //height: 35px;
            }

          }
        }
      }
    }

    .load-more-card {
      text-align: center;
      margin-top: 30px;

      .load-btn {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        border-radius: 10px;
        width: 147px;
        height: 48px;
        background: linear-gradient(270deg, #986efa 35.33%, #d27bb8 130.84%);
        box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
        border-radius: 8px;
      }
    }

    .desktop-list {
      @include lg-mobile {
        display: none;
      }
    }

    .mob-rankers-list {
      display: none;

      @include lg-mobile {
        display: block;
      }
    }
  }

}