@import "@Assets/styles/Variables/Variable.scss";

.main-header-card {
    text-align: center;

    .mock-heading {
        font-weight: 700;
        font-size: 48px;
        line-height: 62px;
        text-align: center;
        color: #101828;

        @include lg-mobile {
            font-size: 24px;
            line-height: 35px;
        }
    }

    .moc-para {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #667085;
        padding-top: 10px;

        @include lg-mobile {
            font-size: 15px;
            line-height: 25px;
        }
    }
}

.mock-test-testimonial {
    padding: 48px 32px 72px 32px;
    background: #F9F5FF;

    @include lg-mobile {
        padding: 30px 20px;
    }


    .testimonial-cards-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        justify-content: center;
        margin-top: 22px;

        .testimonial-cards {
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.05);
            border-radius: 10px;
            padding: 25px;
            width: 400px;
            margin: 10px;

            @include lg-mobile {
                padding: 15px;
            }

            .test-card-img-span {

                @include lg-mobile {
                    margin-right: 12px;
                    margin-top: 10px;
                }

            }

            .testimonial-para {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                /* identical to box height, or 150% */

                display: flex;
                align-items: flex-end;

                color: #101828;
            }

        }
    }

    .testinomal-pagenation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;

       

        span {
            margin-right: 10px;
        }

        span:first-child {
            margin-right: 27px;
        }

        span:last-child {
            margin-left: 12px;
        }
    }
}

.p-paginator {
    flex-wrap: nowrap !important;
}