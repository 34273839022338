.populartest-title-card{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    margin-bottom: 12px;
    text-align: center;

}
.most-popular-series-container {
    padding: 0 60px 35px 60px;

    .most-popular-cards-container {
        display: flex;
        align-items: center;
        margin-top: 18px;

        .m-p-card {
            width: 300px;
            background: #FFFFFF;
            border-radius: 8px;
            padding: 16px;
            margin-right: 24px;

            .subject-card {
                width: 260px;
                height: 207px;
                position: relative;
                margin-bottom: 36px;
                z-index: 1;

            }
            

            


            .subject-title-card {
                position: absolute;
                top: 34%;
                left: 4%;
                background: hsla(0, 0%, 100%, 0.6);
                border-radius: 6px;
                padding: 6px 8px;
                z-index: 10;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #101828;

            }
        }



    }
}