@import "@Assets/styles/Variables/Variable.scss";

.ui-module-1 {
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0px 4px 8px rgba(106, 106, 106, 0.15);
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #18191F;
    // margin: 20px 300px;


    @include lg-mobile {
        margin: 0px;
    }

    .ui-module-info-1 {
        .ui-m-i-header {
            border-bottom: 1px solid #E3EAF2;
            padding: 25px 50px;
            position: sticky;
            left: 0;
            right: 0;
            top: 0;
            background: #fff;
            z-index: 9;
            @include lg-mobile {
                padding: 15px 25px;
                position:relative;
            }
        }

        .ui-module-info-contents {
            padding: 25px 50px;
            @include lg-mobile {
                padding: 15px 25px;
            }
        }
    }


    .ui-p-1 {
        padding: 70px;
    }

    .ui-module-info-h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #18191F;
        display: block;
        margin-bottom: 25px;

    }

    .ui-i-detals {
        display: flex;
        list-style-type: none;
        padding: 0;
        margin: 0;

        @include lg-mobile {
            flex-wrap: wrap;
        }
        li {
            background: #FAFBFF;
            border-radius: 10px;
            min-width: calc(33.3% - 25px);
            min-height: 124px;
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            padding-bottom: 20px;
            padding-right: 20px;
            @include lg-mobile {
                min-width: 100%;
                padding-top: 0px;
                padding-bottom: 0px;
                min-height: 70px;
                max-height: 80px;
            }

            svg {
                @include lg-mobile {
                    max-width: 73px;
                    margin-top: -10px;
                }
            }

            .ui-m-i-details {
                display: block;
                margin-left: 10px;

                .ui-m-i-count {
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 44px;

                    text-align: right;
                    display: block;
                    color: #384057;
                }

                .ui-m-i-names {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    display: block;

                    text-align: right;

                    color: #219653;


                }
            }

            &.m-mark-1 {
                .ui-m-i-names {
                    // margin-top: 41px;
                }
            }

            &.colors-2 {
                .ui-m-i-names {
                    color: #2F80ED !important;
                }
            }

            &.colors-3 {
                .ui-m-i-names {
                    color: #DC8400 !important;
                }
            }
        }

        li+li {
            margin-left: 25px;

            @include lg-mobile {
                margin-left: 0px;
                margin-top: 15px;
            }
        }
    }

    .ui-general-ins-1 {
        //max-height: calc(100vh - 425px);

        .ui-g-ins-row {
            margin-bottom: 20px;
        }


        .ui-general-ins-h1 {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #18191F;
            padding-bottom: 12px;
        }

        .ui-general-ins-list {
            list-style-type: none;
            padding: 0;
            margin: 0;

            .li-row {
                display: flex;
                align-items: flex-start;
                font-weight: 400;
                font-size: 16px;
                line-height: 32px;
                color: #101828;
                .flag-span{
                    color: #8B54FF;
                }

                img {
                    margin-top: 7px;
                    margin-right: 13px;
                }


               
            }

            .q-n-lists {
                list-style-type: none;
                padding: 0 0 0 28px;
                margin: 0 -22px;
            
                @include lg-mobile {
                   margin: 0 -15px;
                }
                li {
                    margin-top: 10px;
                    &.li-row {
                        align-items: center;
                    }
            .q-palettes {
                width: 25px;
                height: 25px;
                background: #FFFFFF;
                border: 1px solid #8B54FF;
                border-radius: 5px;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: -0.06em;
                color: #8B54FF;
                margin-right:10px;
                display: flex;
                justify-content: center;
                margin-right: 19px;
                padding-top: 3px;
                @include lg-mobile {
               margin:17px 15px;
                }
                &.active {
                background: #6FCF97;
                border: 1px solid #6FCF97;
                color: #FFFFFF;
            
                }
                &:hover {
                background: #ccc;
                border: 1px solid #ccc;
                color: #FFFFFF;
                }
                &.active-2 {
                background: #FFD059;
                border: 1px solid #FFD059;
                color: #FFFFFF;
                }
            }
            
            
            
                }
            }

            li {

                svg {
                    margin-right: 10px;
                }

                .module-options-1 {
                    width: 100%;
                }
            }

            li+li {
                margin-top: 10px;
            }
        }
    }




    /* */
    &.ui-lg-module {
        max-width: 1172px;

        .ui-module-header {
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #E3EAF2;
            display: flex;
            justify-content: space-between;
            padding-left: 25px;
            padding-right: 25px;

            .ui-module-name {
                font-weight: 600;
                font-size: 20px;
                line-height: 150%;

                color: #18191F;
            }

            .ui-module-menu {
                display: flex;

                .ui-module-total-times {
                    background: rgba(139, 84, 255, 0.05);
                    border-radius: 6px;
                    padding: 10px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: #8B54FF;
                    display: flex;
                    align-items: center;

                    .ui-module-time {
                        margin-left: 25px;
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 20px;
                    }
                }

                .ui-module-info {
                    background: #FFFFFF;
                    border: 1px solid #8B54FF;
                    box-sizing: border-box;
                    border-radius: 10px;
                    padding: 10px 15px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #8B54FF;
                    margin-left: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        margin-right: 10px;
                    }
                }
            }
        }

        .ui-module-contents {
            display: flex;

            @include lg-mobile {
                display: block;
            }
            .ui-module-cols-1 {
                flex: 1;
                padding: 30px;
            }

            .ui-module-cols-2 {
                border-left: 1px solid #E7ECF4;
                padding: 30px;
                width: 415px;
                @include lg-mobile {
                    border-left: none;
                    width: 100%;
                    padding: 15px;
                    display: none;
                }
            }
        }

        .user-ask-ques-ans {
            padding: 0;
            box-shadow: none;
            border: none;
        }
    }

    .ui-module-footer {
        border-top: 1px solid #E3EAF2;
        display: flex;
        justify-content: center;
        padding: 25px 15px;
        align-items: center;

        @include lg-mobile {
            text-align: center;
        display: block;
        }
        .ui-module-back {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #18191F;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-right: 10px;
            }
        }
    }

}

.ui-button-default {
    padding: 10px 150px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    background: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
    box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
    border-radius: 8px;
    color: #FFFFFF;

    @include lg-mobile{
        padding: 10px 90px;
        width: 100%;
    }
}


