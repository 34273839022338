@import "@Assets/styles/Variables/Variable.scss";

.dashboard-card {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 8px rgba(106, 106, 106, 0.15);
  border-radius: 8px;
  margin-top: 30px;
  width: 100%;

  @include lg-mobile {
    margin-top: 12px;
    padding: 15px;

  }

  .dash-card-header {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #101828;
    padding-bottom: 20px;

    @include lg-mobile {
      font-size: 14px;
      padding-bottom: 0px;
    }
  }


  .duration-attempts-cards {
    display: flex;
    align-items: center;
   

    @include lg-mobile {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .dur-marg{
    margin-top: 15px;

    @include lg-mobile{
      margin-top: 0;
    }
  }

  .test-duration {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    color: #667085;

    @include lg-mobile {
      margin-right: 10px;
      font-size: 15px;
    }

    .duration-svg {
      margin-right: 5px;
    }
  }

  .attempts-card {
    margin-left: 35px;

    @include lg-mobile {
      margin-left: 0;

    }
  }

  .time-span {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #101828;

    @include lg-mobile {
      font-size: 15px;
    }
  }


  .dash-btn-card {
    display: flex;
    align-items: center;

    @include lg-mobile {
      flex-direction: column;
      align-items: flex-start;

    }

    .view-resultes-btn {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #7f56d9;
      background: #ffffff;
      border: 1px solid #7f56d9;
      box-shadow: 0px 4px 8px rgba(106, 106, 106, 0.15);
      border-radius: 8px;
      padding: 6px 22px;
      margin-top: 30px;
      min-width: 148px;
      height: 40px;

      @include lg-mobile {
        margin-top: 10px;
        padding: 6px 11px;
        width: 100%;
        height: 45px;

      }

    }

    .active-btn {
      background: linear-gradient(270deg, #986EFA 35.33%, #D27BB8 130.84%);
      box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
      border-radius: 8px;
      color: #FFFFFF;
      margin-left: 15px;

      @include lg-mobile {
        margin-left: 0;
      }
    }
  }
}


.dash-test-card{
  min-width: 150px;
  display: flex;
}