
$mobile-min-width: 321px;
$mobile-width: 360px;
$tablet-width: 769px;
$tablet-landscape-width: 900px;
$desktop-width: 950px;
$md-desktop-width: 1200px;
$lg-desktop-width: 1700px;
$S3-asset-path: "https://d3lzbbhyvqc4k.cloudfront.net/images";


// mobile responsive mixin

@mixin s-mobile {
	@media (min-width:#{$mobile-min-width - 1px}) and (max-width:#{$mobile-width - 1px}) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: #{$tablet-landscape-width - 1px}) {
		@content;
	}
}

@mixin lg-mobile {
	@media (max-width:#{$desktop-width - 1px}) {
		@content;
	}
}

@mixin tablet {
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}

@mixin md-desktop {
	@media (min-width: #{$desktop-width}) and (max-width: #{$md-desktop-width - 1px}) {
		@content;
	}
}

@mixin ipad {
	@media (min-width: #{$tablet-width - 6px}) and (max-width: #{$desktop-width - 1px}) {
		@content;
	}
}

@mixin lg-desktop {
	@media (min-width: #{$md-desktop-width}) and (max-width: #{$lg-desktop-width - 1px}) {
		@content;
	}
}

@mixin boxshadow {
	box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.07);
	-webkit-box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.07);
	-moz-box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.07);
}
@mixin boxshadowtransition {
	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), -webkit-transform ease-in-out 0.2s;
	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), transform ease-in-out 0.2s;
	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), transform ease-in-out 0.2s,
		-webkit-transform ease-in-out 0.2s;
	-webkit-transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
	-moz-transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
	-o-transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

/*Download and install polyfill for IE*/
:root {
	--green: #25c16f;
	--dark-green: #0ea757;
	--blue: #0287f9;
	--magenta: #cc4783;
	--purple: #8847cc;
	--grey: #4b4b4b;
	--dark-grey: #6a6a6a;
	--light-grey: #8a8a8a;
	--light-gray: #d8d8d8;
	--orange: #f88822;
	--bg: #eaecf0;
	--light-pink: #fffaf6;
	--red: #d82813;
	--yellow: #f8c51c;
	--black: #000000;
	--white: #ffffff;
	--light-green: #25d366;
	--light-blue: #ebf6ff;
	--border-color: #e3ebf2;
	--light-grey-blue: #e3eaf2;
	--light-grey-2: #fafafa;
	--light-greenb: #d8fde8;

	--theme: #FFB21B;
	--theme-background: #FFF5E1;
	--theme-hover: #FFD88B;;
	--theme-hover-background: #FFF5E1;
	--theme-filter-background: #FFF5E1;
}
