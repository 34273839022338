@import "@Assets/styles/Variables/Variable.scss";
.test_name::first-letter {
    text-transform:capitalize;
}

.all-classes-tabs-container {
    margin: 30px 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;

    @include lg-mobile {
        margin: 0px 35px;
        text-align: center;
        gap: 10px;
    }

    .a-cls-t-card {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #101828;
        padding: 16px 25px;
        background: #FFFFFF;
        border-radius: 8px;
        cursor: pointer;

        @include lg-mobile {
            width: 100%;
            margin: 10px 0;
        }
    }

    .active-cls-card {
        background: rgba(127, 86, 217, 0.15);
        border: 1px solid #D0D5DD;
        color: #7F56D9;
    }

    .a-cls-more-classes {
        color: #7F56D9;
    }
}
