@import "@Assets/styles/Variables/Variable.scss";

/*-------------------------------------header-section-css-------------------------*/
.header-container {
    padding: 20px 60px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include lg-mobile {
        display: none;
    }

    .header-logo {
        img {
            width: 158px;
            height: 34px;
            margin-right: 35px;

        }

    }

    .header-searchbox {
        display: flex;
        align-items: center;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        height: 45px;
        padding: 8px 5px  8px 16px;
        background: #FFFFFF;
        width: 350px;

        .search-svg-img {
            width: 22px;
        }

        @include lg-mobile {}

        .search-input {
            border: none;
            outline: none;
            margin: 0 10px;
            width: 100%;

        }

        .search-clear-card {
            width: 52px;
            height: 36px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;

            // .search-svg-img {
            //     width: 15px;
            //     height: 15px;
            //     margin-top: 6px;
            // }

        }

        .search-drop-down-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 8px 16px;
            gap: 8px;
            width: 52px;
            height: 36px;
            background: #F9F5FF;
            border-radius: 8px;

            .search-drop-box {
                display: flex;
                align-items: center;
                font-weight: 600;
                padding: 8px 16px;
                font-size: 14px;
                line-height: 20px;
                color: #7F56D9;
                background: #F9F5FF;
                border-radius: 8px;
                width: min-content;


                span {
                    margin-left: 13px;
                }
            }
        }
    }

    .header-searchbox:hover {
        border: 1px solid #986EFA;
    }

    .dropdown-container {
        display: flex;
        justify-content: initial;
    }

    .header-dropdowns-card {
        margin: 0 5px;

        .drop-down-header-sec {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #101828;

            span {
                margin-left: 13px;
            }
        }
    }

    .sign-card {


        a {

            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #101828;
            text-decoration: none;
        }

        &:hover {
            a {
                color: #986EFA;
            }
        }
    }

    .create-accnt-btn {
        padding: 10px 16px;
        background: linear-gradient(270deg, #986EFA 35.33%, #D27BB8 130.84%);
        box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
        border-radius: 8px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin-left: 25PX;
    }

    .user-loged-in {
        display: flex;
        align-items: center;

        .user-name {

            background: #F9F5FF;
            border: 1px solid #7F56D9;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            width: 37px;
            height: 37px;
            border-radius: 50%;
            color: #7F56D9;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
        }

    }



}




/*------------------------------------end-header-section-css-------------------------*/



.login-drop-down-prof-dash {
    list-style: none;
    margin: 0;
    padding-left: 0;
    z-index: 10;
}

.dropdown-ui-1 {

    padding: 1rem;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #101828;
    text-transform: capitalize;


    a {
        color: #101828;
        text-decoration: none;
        display: flex;
        align-items: center;

        .down-arrow-svg {
            svg {
                margin-left: 10px;
            }
        }
    }
}





.dropdown-ui-container {
    background: #FFFFFF;
    border: 1px solid #DEE2E6;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 8px;
    visibility: hidden;
    opacity: 0;
    min-width: 240px;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: 15px;
    left: -85%;
    display: none;

    .user-dropdown-li {
        padding: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.03em;
        color: #101828;
        border-bottom: 1px solid #D0D8E3;

        .head-logout-img {
            margin-right: 22px;
        }

        .head-active-logout-img {
            margin-right: 22px;
            display: none;

        }

    }

    .user-dropdown-li:hover {
        .head-logout-img {
            margin-right: 22px;
            display: none;
        }

        .head-active-logout-img {
            margin-right: 22px;
            display: block;

        }

    }



    .user-dropdown-li:hover {
        background: #F9F5FF;

        a {
            color: #7F56D9;
        }

    }
}

.dropdown-ui-1:hover>ul,
ul .dropdown-ui-1 .dropdown-ui-container:hover {
    visibility: visible;
    opacity: 1;
    display: block;
}


.bg-home-page {
    background: linear-gradient(0deg, rgba(223, 225, 238, 0.9), rgba(223, 225, 238, 0.9)) !important;
}

.auto-search-input {
    height: 42px;

    .p-inputtext {
        border: none;
        border-radius: 0;
        outline: none;
    }

    .p-inputtext:enabled:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
        border-color: none;
    }
}



.search-suggetion-box {
    display: flex;
    align-items: flex-start;

    .search-svg-img {
        width: 15px;
        height: 15px;
        margin-right: 10px;
        margin-top: 6px;
    }

    .sug-head {

        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        color: #7F56D9;


    }

    .sub-sug-card {
        font-weight: 300;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.3px;
        color: #667085;
        margin-top: 3px;

        span {
            margin-right: 3px;
        }
    }
}

.p-autocomplete {
    width: 100%;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: #495057;
    background: #F9F5FF;
}

.p-autocomplete-panel {
    left: 252px !important;

    @include lg-mobile{
        left: 30px !important;
    }
}