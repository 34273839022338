@import "@Assets/styles/Variables/Variable.scss";

.test-details-page {
    background: #F4F5F7;



    .stnd-cls-card-btn {
        text-align: center;

        .take-test-btn {
            border: 1px solid #7F56D9;
            border-radius: 8px;
            padding: 15px 39px;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            color: #7F56D9;
        }
    }

    .mock-test-englist-dst-container {
        padding: 20px 80px 20px 30px;
        background: #EFEBF5;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;

        .m-t-elg-l-tabs {
            display: flex;

            .m-t-elgl-span {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.03em;
                color: #667085;
                margin: 3px 5px 0 5px;
            }

            .active-elg-span {
                color: #7F56D9;
            }
        }

        .m-t-elg-l-header {
            font-weight: 700;
            font-size: 40px;
            line-height: 60px;
            color: #000000;
            margin: 27px 0 0 50px;


            .m-t-elg-l-head-span {
                background: linear-gradient(180deg, #D27BB8 -39.5%, #986EFA 116%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;

            }

        }

        .m-t-elg-l-img-card {}
    }

    .about-benefits-comprise-section {
        padding: 0 60px 50px 65px;


        @include lg-mobile {
            padding: 0 15px 15px 15px;
        }

        h1 {
            font-weight: 700;
            font-size: 36px;
            line-height: 62px;
            color: #101828;
        }

        p {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #667085;
            margin: 5px 0;

        }

        .text-description-card {
            order: 0;

            @include lg-mobile{
                margin-top: 45px;
            }
        }

        .abt-b-c-about-test {
            margin-top: 30px;

            .about-test-header {
                font-weight: 700;
                font-size: 36px;
                line-height: 62px;
                color: #101828;
            }

            .about-test-ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #667085;
                    margin: 5px 0;
                }
            }

            .benefits-test-ul {
                margin: 0;
                padding: 0;
                list-style: none;


                li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #667085;
                    margin: 5px 0;
                }

                li:before {
                    content: "\2022";
                    color: #7F56D9;
                    font-size: 20px;
                    font-weight: 600;
                    display: inline-block;
                    margin-right: 10px;

                }
            }
        }


        .eng-litera-card {
            width: 390px;
            background: #FFFFFF;
            box-shadow: 0px 24px 54px rgba(16, 24, 40, 0.06);
            border-radius: 8px;
            padding: 30px;
            margin-top: 28px;
            margin-left: 90px;

            @include lg-mobile {
                width: 100%;
                margin-left: 0;
                height: auto;
                order: -1;
            }

            .eng-ltr-bg-img {
                width: 335px;
                height: 207px;
                margin-bottom: 28px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;



                @include lg-mobile {
                    width: 100%;


                }

                .eng-subject-img {
                    width: 100%;
                    height: 207px;
                }



                .title-card {
                    padding: 14px;
                    background: hsla(0, 0%, 100%, 0.6);
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    color: #101828;
                    text-align: center;


                }

                .subject-test-levl-card {
                    position: absolute;
                    top: 6%;
                    right: 4%;
                    background: rgba(255, 255, 255, 0.85);
                    border-radius: 6px;
                    padding: 5px 10px;
                    z-index: 10;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #101828;

                }

            }

            .take-test-card {

                @include lg-mobile {
                    text-align: center;
                }

                .take-test-btn {
                    padding: 20px;
                    background: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
                    box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
                    border-radius: 8px;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFFFFF;
                    margin-bottom: 28px;
                    width: 335px;

                    @include lg-mobile {
                        width: 100%;
                    }

                }

            }

            .test-details-card {


                .test-detail-heading {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #101828;
                }

                .test-detail-info {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #667085;
                    width: 60%;
                    text-transform: capitalize;
                    text-align: right;
                    display: flex;
                    justify-content: flex-end;
                    .test-span-info{
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }










        }

        .similar-test-cards-container {

            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;


            .similar-test-cards {
                background: #FFFFFF;
                box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.05);
                border-radius: 8px;
                width: 545px;
                display: flex;
                align-items: center;
                padding: 15px;
                margin-right: 30px;
                margin-bottom: 30px;



                .test-level-head {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 20px;
                    color: #0A033C;
                    padding-bottom: 20px;
                }
            }
        }



    }
}

.justify_content {
    justify-content: space-between;
}


.custom-tooltip-btn-topic {
    width: 33px;
    height: 24px;
    background: rgba(127, 86, 217, 0.08);
    border: 1px solid rgba(127, 86, 217, 0.2);
    border-radius: 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #7F56D9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

}

.custom-tooltip-btn-subject {
    width: 33px;
    height: 24px;
    background: rgba(127, 86, 217, 0.08);
    border: 1px solid rgba(127, 86, 217, 0.2);
    border-radius: 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #7F56D9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

}

.custom-tooltip-btn-chapter {
    width: 33px;
    height: 24px;
    background: rgba(127, 86, 217, 0.08);
    border: 1px solid rgba(127, 86, 217, 0.2);
    border-radius: 20px;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #7F56D9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

}

.tooltip-topic {
    background-color: red;


    span {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        color: #667085;
    }
}