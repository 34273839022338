@import "@Assets/styles/Variables/Variable.scss";

.dashboard-page-container {
  padding: 28px 45px 45px 45px;

  @include lg-mobile{
    padding: 20px;
  }

  .dash-content-card{
    padding-right: 140px;
    @include lg-mobile{
      padding-right: 20px;
    }

  }

  .dash-header {
    .dash-heading {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #101828;
      text-transform: capitalize;

      @include lg-mobile{
        font-size: 18px;
      }
    }
    .dash-para {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #667085;
      padding: 20px 0;
      @include lg-mobile{
        font-size: 13px;
        padding: 3px 0  10px 0;
      }
    }
  }
  .dashboard-tabs {
    .dash-tabs-ul {
      border-bottom: 1px solid #eaecf0;
      list-style: none;
      display: flex;

      @include lg-mobile{
        width: 100%;
      }

      .dash-tabs-li {
        margin-right: 40px;
        padding-bottom: 10px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #101828;
        cursor: pointer;
        @include lg-mobile{
          font-size: 15px;
          margin-right: 10px;
        }
      }
    }

    .active-tab {
      border-bottom: 3px solid #7f56d9;
    }
  }

 
}


.no-data-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;

  img{
    margin-left: 10px;
  }


  .n-d-head{
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin-top: 35px;   
    color: #667085;

    @include lg-mobile{
      margin-top: 20px;
    }

    .n-d-active-span{
      font-weight: 500;
    }
  }
}
