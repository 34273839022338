@import "@Assets/styles/Variables/Variable.scss";

.test-type-selection-container {
  padding: 28px 400px 45px 45px;

  @include lg-mobile {
    padding: 20px;
  }

  .test-typ-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.06em;
    color: #101828;
  }

  .test-level-indicater {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 18px;
    padding-right: 200px;

    @include lg-mobile {
      padding-right: 0px;
    }

    .t-l-head {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #101828;
    }

    .t-level-card {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #101828;
    }
  }

  .level-svg-cards {
    display: flex;
    align-items: center;

    .level-svg-span {
      margin-right: 20px;

    }
  }

  .test-content-card {
    .test-cnt-heading {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #101828;
      padding-top: 28px;
    }

    .input-form-card {
      margin-right: 180px;

      @include lg-mobile {
        margin-right: 0;
      }

      .input-form-control {
        background: #ffffff;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        width: 100%;
        padding: 14px 18px;
        margin-top: 18px;
      }

      .prime-ract-input {
        width: 100%;
        margin-top: 18px;
        box-shadow: none;
      }
    }


  }

  .test-back-btn {
    border-radius: 8px;
    border: 1px solid #7F56D9;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding: 11px 38px;
    color: #7F56D9;
    margin-top: 38px;


    @include lg-mobile {
      width: 100%;
      height: 45px;
    }
  }

  .test-cont-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    padding: 11px 38px;
    color: #ffffff;
    background: linear-gradient(270deg, #986efa 35.33%, #d27bb8 130.84%);
    box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
    border-radius: 8px;
    margin-top: 38px;

    @include lg-mobile {
      width: 100%;
      height: 45px;
    }
  }

  .type-cards-container {

    .type-card {
      background: #FFFFFF;
      border: 1px solid #EAECF0;
      box-shadow: 0px 4px 8px rgba(106, 106, 106, 0.15);
      border-radius: 8px;
      padding: 17px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0;
      cursor: pointer;

      .type-card-head {
        font-weight: 500;
        font-size: 16px;
        line-height: 32px;
        color: #101828;
      }

      .type-svg {

        .active-right-arrow {
          display: none;
        }

      }

    }



    .active-type-card {
      border: 1px solid #7F56D9;

      .type-card-head {

        color: #7F56D9;
      }

      .type-svg {

        .right-arrow {
          display: none;
        }

        .active-right-arrow {
          display: block;
        }

      }
    }

    .type-card:hover {

      border: 1px solid #7F56D9;

      .type-card-head {

        color: #7F56D9;
      }

      .type-svg {

        .right-arrow {
          display: none;
        }

        .active-right-arrow {
          display: block;
        }
      }

    }
  }
}