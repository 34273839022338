@import "@Assets/styles/Variables/Variable.scss";

.test-listining {
    background: #F4F5F7;
    padding-bottom: 50px;

    .mock-test-listing-header {
        font-weight: 700;
        font-size: 36px;
        line-height: 62px;
        color: #101828;

        @include lg-mobile {
            font-size: 25px;
            text-align: center;
            line-height: 40px;
        }
    }

    .stnd-cls-card-btn {
        text-align: center;

        .take-test-btn {
            border: 1px solid #7F56D9;
            border-radius: 8px;
            padding: 15px 39px;
            font-weight: 600;
            font-size: 18px;
            line-height: 20px;
            color: #7F56D9;
        }
    }

    .testinomal-pagenation {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 60px;

        span {
            margin-right: 10px;
        }

        span:first-child {
            margin-right: 27px;
        }

        span:last-child {
            margin-left: 12px;
        }
    }


    .mock-test-listining-container {
        padding: 20px 10px 20px 30px;
        background: #EFEBF5;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        margin: 0 60px;

        @include lg-mobile {
            padding: 20px;
            margin: 0;
        }

        .m-t-l-tabs {
            display: flex;

            .m-t-l-span {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.03em;
                color: #667085;
                margin: 3px 5px 0 5px;
            }

            .active-span {
                color: #7F56D9;
            }
        }

        .m-t-l-header {
            font-weight: 700;
            font-size: 40px;
            line-height: 60px;
            color: #000000;
            margin: 27px 0 0 30px;


            .m-t-l-head-span {
                background: linear-gradient(180deg, #D27BB8 -39.5%, #986EFA 116%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;

            }

        }

        .m-t-l-img-card {}
    }


    .standard-classes-container {
        padding: 0 60px 35px 60px;

        .stnd-cls-cards-container {
            margin-top: 18px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;


            .stnd-cls-card {
                background: #FFFFFF;
                border-radius: 8px;
                padding: 30px 20px 25px 20px;
                text-align: center;
                width: 290px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 40px;

                @include lg-mobile {
                    margin-right: 0;
                    margin-left: 0;
                    width: 100%;
                }

                .stnd-cls-card-img {
                    width: 50px;
                    height: 50px;
                }


                .stnd-cls-cards-header {
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 29px;
                    color: #101828;
                    padding-top: 20px;
                }

                .stnd-cls-cards-para {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 30px;
                    text-align: center;
                    color: #667085;
                    padding: 11px 0 25px 0;
                    margin-bottom: 25px;
                    height: 105px;

                    @include lg-mobile {
                        height: auto;
                    }

                }




            }
        }


    }

    .most-popular-series-container {
        padding: 0 60px 35px 60px;

        .most-popular-cards-container {
            display: flex;
            align-items: inherit;
            margin-top: 18px;

            .m-p-card {
                width: 300px;
                background: #FFFFFF;
                border-radius: 8px;
                padding: 16px;
                margin-right: 24px;

                @include lg-mobile {
                    margin-right: 0;
                    width: 100%;
                }

                .subject-card {
                    width: 100%;
                    height: 207px;
                    position: relative;
                    margin-bottom: 36px;
                    z-index: 1;
                    

                    @include lg-mobile {
                        width: 100%;
                    }

                }

                .english-card {
                    background-image: url("https://i.postimg.cc/7h1RgH1Z/Frame-26.png");



                }

                .physics-card {
                    background-image: url("https://i.postimg.cc/Dyb2WDMJ/Frame-26.png");

                }

                .econamics-card {
                    background-image: url("https://i.postimg.cc/NjNMwT9B/Frame-26-1.png");


                }

                .computer-card {
                    background-image: url("https://i.postimg.cc/zvft699J/Frame-26-3.png");

                }

                .subject-title-card {
                    position: absolute;
                    top: 34%;
                    left: 4%;
                    background: hsla(0, 0%, 100%, 0.6);
                    border-radius: 6px;
                    padding: 6px 8px;
                    z-index: 10;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #101828;

                }
            }



        }
    }

    .other-test-recmnd-container {
        padding: 0 60px 35px 60px;

        .other-test-tabs {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .other-test-tabs-card {
                padding: 17px 5px;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                color: #667085;
                margin-right: 37px;
            }

            .active-tab-other {
                color: #7F56D9;
                border-bottom: 2px solid #7F56D9;
            }
        }

        .others-test-cards-container {
            display: flex;
            align-items: center;
            margin-top: 38px;

            .othr-test-card:last-child {
                margin-right: 0;
            }

            .othr-test-card {
                width: 312px;
                background: #FFFFFF;
                border-radius: 8px;
                margin-right: 28px;

                .o-t-c-header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid #EAECF0;
                    padding: 16px 0;

                    .o-t-c-heading {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                        color: #101828;
                        padding-top: 16px;
                        padding-bottom: 6px;

                    }

                    .o-t-c-expires-date {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 30px;
                        color: #667085;

                    }

                }


                .question-paper {
                    padding: 4px 20px;
                    border-bottom: 1px solid #EAECF0;

                    .qust-papr-head {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 30px;
                        color: #101828;
                    }

                    .duration-marks-card {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 30px;
                        color: #667085;

                    }

                }
            }
        }
    }


    .subscrp-update-mock-test {
        margin: 35px 60px 0 60px;
        background: #F9F5FF;
        border-radius: 8px;
        padding-top: 60px;
        padding-bottom: 115px;
        display: flex;
        justify-content: space-between;

        .subp-left-user-cards {

            .subp-left-user-1 {
                img {
                    margin-left: 60px;
                }

            }

            .subp-left-user-2 {
                img {

                    margin: 45px 0 70px 180px;
                }


            }

            .subp-left-user-3 {
                img {
                    margin-left: 80px;
                }


            }
        }

        .subp-right-user-cards {


            .subp-right-user-1 {

                img {
                    margin-left: 80px;
                }

            }

            .subp-right-user-2 {

                img {

                    margin: 70px 182px 45px 0;

                }

            }

            .subp-right-user-3 {

                img {
                    margin-left: 120px;
                }

            }
        }

        .subp-update-card {
            text-align: center;
            margin-top: 20px;

            .sub-upd-heading {
                font-weight: 700;
                font-size: 45px;
                line-height: 55px;
                color: #101828;
            }

            .sub-upd-para {
                font-weight: 400;
                font-size: 16px;
                line-height: 30px;
                padding: 20px 0 40px 0;
                color: #667085;
            }

            .subscribe-email-box {
                display: flex;
                align-items: center;
                background: #FFFFFF;
                border-radius: 8px 0px 0px 8px;
                height: 50px;
                width: 500px;
                margin-left: 70px;

                input {
                    border: none;
                    outline: none;
                    padding-left: 20px;
                    width: 100%;
                }


                .subsc-btn {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                    color: #FFFFFF;
                    background: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
                    border-radius: 0px 8px 8px 0px;
                    height: 50px;
                    padding: 13px 30px;

                }
            }
        }



    }
}