@import "@Assets/styles/Variables/Variable.scss";

.leader-board-container {
  padding: 28px 60px 52px 45px;

  @include lg-mobile {
    padding: 15px;
  }

  .leader-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.06em;
    color: #101828;
    display: flex;
    align-items: center;
    margin-bottom: 28px;

    .back-btn-span {
      cursor: pointer;
    }

    img {
      margin-top: 10px;
      margin-right: 15px;
    }
  }



  /*-------------------test-result-------------------------*/
  .test-paper-name {
    color: #101828;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    /* 133.333% */

    @include lg-mobile{
      font-size: 18px;
      line-height: 19px;
    }
  }

  .paper-result {
    background: #ffffff;
    border: 1px solid #e3eaf2;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(106, 106, 106, 0.15);
    border-radius: 10px;
    //padding: 33px 58px;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    @include lg-mobile {
      display: flex;
      flex-wrap: wrap;
      // padding: 10px;
    }

    .paper-total-number {
      .paper-chart-text-1 {
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        color: #18191f;
      }

      .paper-chart-text-2 {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: rgba(24, 25, 31, 0.75);
      }
    }

    .ui-list-sec {
      flex-direction: column;
    }

    .speed-test-paper-lists {
      padding: 0;
      margin: 0 0 0 0px;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      max-width: 427px;

      li {
        width: 100%;
        margin: 13px 10px;


        @include lg-mobile {
          width: 100%;
          margin: 10px 7px;
        }

        .paper-d-box-1 {
          display: block;
          background: #f7f9fc;
          border-radius: 10px;
          min-height: 85px;
          padding: 19px 18px;
          background: #FAFBFF;
          border: 1px solid #EAECF0;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include lg-mobile {
            padding: 15px;
            min-height: 95px;
          }

          .paper-name-1 {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #101828;
            display: block;
            margin-bottom: 4px;
            font-weight: 400;

          }

          .paper-status-1 {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #101828;
            display: block;
            padding-top: 8px;
          }
        }
      }
    }

    .paper-lists {
      padding: 0;
      margin: 0 0 0 0px;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      max-width: 427px;

      li {
        width: 190px;
        margin: 13px 10px;

        @include lg-mobile {
          width: calc(50% - 18px);
          margin: 10px auto;
        }

        .paper-d-box-1 {
          display: block;
          background: #f7f9fc;
          border-radius: 10px;
          min-height: 85px;
          padding: 19px 18px;
          background: #FAFBFF;
          border: 1px solid #EAECF0;
          border-radius: 10px;

          @include lg-mobile {
            padding: 15px;
            min-height: 95px;
          }

          .paper-name-1 {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #101828;
            display: block;
            margin-bottom: 4px;
            font-weight: 400;

          }

          .paper-status-1 {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #101828;
            display: block;
            padding-top: 8px;
          }
        }

        &.highlight-result {

          // width: 200px;
          .bg-betterluck {
            background: #ffecec;
            border: 1px solid rgba(235, 87, 87, 0.5);
          }

          .overallscore-bg {
            background: #f3eeff;
            border: 1px solid rgba(139, 84, 255, 0.5);
          }

          .result-card-bg {
            background: #e3fced;
            border: 1px solid rgba(88, 179, 123, 0.5);
          }

          .paper-d-box-1 {
            display: flex;
            justify-content: space-between;
            min-height: 107px;
            padding: 26px 0 26px 26px;

            @include lg-mobile {
              padding: 14px 0 14px 14px;
            }

            .better-name-1 {
              font-weight: 600;
              font-size: 20px;
              line-height: 25px;
              color: #eb5757;

              @include lg-mobile {
                font-size: 14px;
              }
            }

            .paper-name-1 {
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: rgba(24, 25, 31, 0.75);

              @include lg-mobile {
                font-size: 12px;
              }
            }

            .paper-status-1 {
              font-weight: 700;
              font-size: 24px;
              line-height: 19px;
              color: #8b54ff;

              @include lg-mobile {
                font-size: 20px;
                line-height: 22px;
              }
            }

            .paper-status-pass {
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              color: #58b37b;

              @include lg-mobile {
                font-size: 16px;
                line-height: 22px;

              }


            }

            .paper-status-fail {
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              color: #EB5757;

              @include lg-mobile {
                font-size: 16px;
                line-height: 22px;

              }
            }
          }
        }
      }
    }

    .ui-list-sec {
      padding: 0;
      margin: 0 0 0 0px;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      max-width: 427px;

      .leader-board-li {
        width: 190px;
        margin: 13px 10px;

        @include lg-mobile {
          width: calc(100% - 20px);
          margin: 10px auto;
        }
      }

      li {
        width: 190px;
        margin: 13px 10px;

        @include lg-mobile {
          width: calc(50% - 18px);
          margin: 10px auto;
        }

        .paper-d-box-1 {
          display: block;
          background: #f7f9fc;
          border-radius: 10px;
          min-height: 85px;
          padding: 19px 18px;
          background: #FAFBFF;
          border: 1px solid #EAECF0;
          border-radius: 10px;

          @include lg-mobile {
            padding: 15px;
            min-height: 95px;
          }

          .paper-name-1 {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #101828;
            display: block;
            margin-bottom: 4px;
            font-weight: 400;

          }

          .paper-status-1 {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #101828;
            display: block;
            padding-top: 8px;
          }
        }

        &.highlight-result {

          // width: 200px;
          .bg-betterluck {
            background: #ffecec;
            border: 1px solid rgba(235, 87, 87, 0.5);
          }

          .overallscore-bg {
            background: #f3eeff;
            border: 1px solid rgba(139, 84, 255, 0.5);
          }

          .result-card-bg {
            background: #e3fced;
            border: 1px solid rgba(88, 179, 123, 0.5);
          }

          .paper-d-box-1 {
            display: flex;
            justify-content: space-between;
            min-height: 107px;
            padding: 26px 0 26px 26px;

            @include lg-mobile {
              padding: 14px 0 14px 14px;
            }

            .better-name-1 {
              font-weight: 600;
              font-size: 20px;
              line-height: 25px;
              color: #eb5757;

              @include lg-mobile {
                font-size: 14px;
              }
            }

            .paper-name-1 {
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: rgba(24, 25, 31, 0.75);

              @include lg-mobile {
                font-size: 12px;
              }
            }

            .paper-status-1 {
              font-weight: 700;
              font-size: 24px;
              line-height: 19px;
              color: #8b54ff;

              @include lg-mobile {
                font-size: 20px;
                line-height: 22px;
              }
            }

            .paper-status-pass {
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              color: #58b37b;

              @include lg-mobile {
                font-size: 16px;
                line-height: 22px;

              }


            }

            .paper-status-fail {
              font-weight: 600;
              font-size: 20px;
              line-height: 24px;
              color: #EB5757;

              @include lg-mobile {
                font-size: 16px;
                line-height: 22px;

              }
            }
          }
        }
      }
    }

    .paper-total-number-details {
      padding: 25px 26px;
      min-width: 500px;

      li {
        margin: 25px 10px;

        @include lg-mobile {
          margin: 8px;
        }
      }

      @include lg-mobile {
        padding: 5px;
        min-width: 100px;
        order: 2;
        border-top: 1px solid #DEE2E6;
      }
    }

    .paper-total-number {
      flex: 1;
      border-left: 1px solid #dee2e6;
      padding: 15px;

      @include lg-mobile {
        padding: 5px;
        order: 1;
        border-left: none;

      }
    }
  }

  .your-ranked-card {
    background: #fffced;
    border: 1px solid rgba(242, 187, 19, 0.5);
    border-radius: 10px;
    padding: 10px 0 10px 26px;
    min-height: 111px;
    min-width: 400px;

    @include lg-mobile {
      min-width: 100px;
      width: 100%;
    }

    .view-leader-board-card {
      text-align: right;
      padding: 0 10px 17px 0;

      .v-l-b-a {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-decoration-line: underline;
        color: #f2bb13;
        cursor: pointer;
      }
    }

    .your-ranked-content-card {
      display: flex;
      justify-content: space-between;
    }

    .rank-score-card {
      display: flex;
      flex-direction: column;

      .better-name-1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: rgba(24, 25, 31, 0.75);
      }

      .rancked-scored {
        font-weight: 700;
        font-size: 32px;
        line-height: 39px;
        color: #f2bb13;
      }
    }
  }

  .total-attempts-sec {
    background: #e4f7fd;
    border: 1px solid rgba(71, 199, 239, 0.5);
    border-radius: 10px;
    min-height: 107px;
    padding: 26px 0 26px 26px;
    display: flex;
    justify-content: space-between;

    @include lg-mobile {
      padding: 14px 0 14px 14px;
    }

    .attempts-sec-card {
      display: flex;
      flex-direction: column;

      .total-attempts-head {
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        color: rgba(24, 25, 31, 0.75);

        @include lg-mobile {
          font-size: 12px;
        }
      }

      .attempts-status {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #47c7ef;
        margin-top: 8px;

        @include lg-mobile {
          font-size: 20px;
        }
      }
    }
  }

  .time-sec {
    background: #feede0;
    border: 1px solid rgba(249, 131, 49, 0.5);
    border-radius: 10px;
    padding: 26px 0 26px 26px;
    min-height: 107px;
    display: flex;
    justify-content: space-between;

    @include lg-mobile {
      padding: 14px 0 14px 14px;
    }

    .time-status-card {
      display: flex;
      flex-direction: column;

      .time-head {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: rgba(24, 25, 31, 0.75);
      }

      .timer-status {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #f98331;
        margin-top: 8px;

        @include lg-mobile {
          font-size: 16px;
        }
      }
    }
  }

  /*---------------------review-------------------------*/

  .review-mock-test-container {
    margin-top: 40px;


    .review-mock-test-tabs {
      display: flex;
      align-items: center;
      gap: 35px;
      border-bottom: 1px solid #EAECF0;


      .r-m-t-heading {
        color: #101828;
        font-size: 20px;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        padding-bottom: 15px;
        cursor: pointer;
        border: 3px solid transparent;

        @include lg-mobile{
          font-size: 14px;
        }
      }

      .active-tab {
        border-bottom: 3px solid #7F56D9;
      }

    }



    .question-review-card {
      padding: 30px 0px 30px 0;
      background: #FFFFFF;
      border: 1px solid #EAECF0;
      box-shadow: 0px 4px 8px rgba(106, 106, 106, 0.15);
      border-radius: 10px;
      margin: 25px 0;

      .rev-qust-no-head {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #101828;
        padding-bottom: 8px;
        padding-left: 30px;
        display: flex;
        align-items: center;

        .pipe-span {
          margin: 0 8px;
        }

        .quest-time-span {
          display: flex;
          align-items: center;
          color: #7F56D9;
          font-size: 14px;
          font-weight: 400;
          line-height: normal;

          img {
            margin-right: 4px;
          }
        }
      }

      .qust-subject-card {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include lg-mobile {
          flex-wrap: wrap;
        }

        .subject-span {
          background: #FFFFFF;
          border: 1px solid #EAECF0;
          border-radius: 5px;
          padding: 8px 20px;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          color: #667085;
          margin-right: 30px;
          margin-left: 15px;
          white-space: nowrap;
        }

        .level-subj-sec {
          display: flex;
          margin-left: 25px;


          @include lg-mobile {
            order: 1;
            margin-bottom: 8px;
          }


        }

        .subject-level-span {
          border-radius: 6px;
          border: 1px solid #EAECF0;
          background: #F7F9FC;
          color: #101828;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          padding: 6px 9px;
          min-width: 78px;
        }
      }

      .review-qust {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #101828;
        padding-left: 30px;

        @include lg-mobile {
          font-size: 16px;
          order: 2;
        }
      }

      .reviw-opt-card {
        margin-top: 10px;

        .review-options {
          padding: 26px 29px;
          font-weight: 500;
          font-size: 17px;
          line-height: 21px;
          color: #101828;
          border-bottom: 1px solid #EAECF0;

          .opt-span {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.06em;
            color: #101828;

            .option-value {
              font-size: 16.5px;
              padding-left: 12px;
            }


            .correct-opt-span {
              color: #58B37B;
              font-size: 14px;
              font-weight: 600;
              line-height: normal;
            }


          }
        }

        .correct-question {
          background: #E3FCED;

          .Correct-svg {
            display: block;
          }
        }

        .missed-answer {
          background: #FFDBBA;



          .Correct-svg {
            display: block;
          }

          .missed-opt-span {
            color: #F88822;
            text-align: right;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
          }
        }

        .incorrect-qust {
          background: #FFECEC;

          .incorrect-svg {
            display: block;
          }
        }
      }

      .incorrect-svg {
        display: none;
      }

      .Correct-svg {
        display: none;
      }

      .answer-status {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        padding-left: 30px;
        margin-top: 12px;
        text-decoration: none;
      }

      .status-correct {
        color: #58B37B;
      }

      .status-incorrect {
        color: #EB5757;
      }

      .status-skipped {
        padding: 11px 0px;
        color: #7F56D9;
        background: #F9F5FF;
        border: 1px solid rgba(127, 86, 217, 0.5);
        border-radius: 5px;
        margin-left: 30px;
        margin-right: 30px;

        @include lg-mobile {
          margin: 0 20px;
        }

      }
    }


  }

}

.q-review-card {
  padding: 10px 30px;
  margin-top: 15px;

  .button-links {
    display: inline-flex;
    border-radius: 4px;
    border: 1px solid #a78be5;
    color: #a78be5;
    padding: 7px 20px;
    cursor: pointer;
  }
}

.q-review-card-text {
  padding: 10px 30px;
}

.visible{
  visibility: visible;
}

.hidden{
  visibility: hidden;
}