@import "@Assets/styles/Variables/Variable.scss";

.most-popular-series-container {
    padding: 0 60px 0px 60px;

    @include lg-mobile {
        padding: 20px 25px !important;
    }

    .mock-test-listing-header {

        // margin-top: 50px;
        @include lg-mobile {
            margin-top: 25px;
        }
    }

    .most-popular-cards-container {
        display: flex;
        flex-wrap: wrap;
        align-items: inherit;
        margin-top: 18px;
        gap: 25px;


        @include lg-mobile {
            flex-wrap: wrap;
        }

        .m-p-card {
            background: #FFFFFF;
            border-radius: 8px;
            padding: 16px;
            width: calc(25% - 20px);

            @include lg-mobile {
                width: 100%;
                margin: 10px 0;


            }

            @include tablet {
                width: calc(50% - 50px);

            }

            .subject-card {
                width: 100%;
                height: auto;
                margin-bottom: 36px;
                position: relative;
                z-index: 1;



            }

            .subject-img {
                width: 100%;
                height: auto;

            }

            .subject-title-card {
                position: absolute;
                top: 38%;
                left: 0%;
                right: 0%;
                background: rgba(255, 255, 255, 0.85);
                border-radius: 6px;
                padding: 6px 8px;
                z-index: 10;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #101828;
                text-align: center;

            }

            .subject-test-levl-card {
                position: absolute;
                top: 6%;
                right: 4%;
                background: rgba(255, 255, 255, 0.85);
                border-radius: 6px;
                padding: 5px 10px;
                z-index: 10;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #101828;

            }


            .subject-test-descrip {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #101828;
                margin-bottom: 15px;
            }
        }

        .subject-card {
            display: flex;
            align-items: flex-start;
            width: calc(50% - 50px);
            background: #FFFFFF;
            box-shadow: 0px 18px 36px rgba(75, 75, 75, 0.1);
            border-radius: 8px;
            padding: 16px;

            @include lg-mobile {
                width: 100%;
                flex-wrap: wrap;
            }

            .subjct-img {
                width: 280px;
                height: 207px;
                min-width: 280px;

                @include lg-mobile {
                    width: 100%;
                    height: auto;
                }
            }

            .s-c-right {
                text-align: left;
                margin-left: 22px;

                @include lg-mobile {
                    margin-left: 0;
                    text-align: center;
                    width: 100%;
                    margin-top: 15px;
                }

                .s-c-r-head {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 29px;
                    color: #101828;
                    padding-bottom: 12px;
                }

                .s-c-r-para {
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    color: #667085;
                    padding-bottom: 0px;
                    height: 75px;
                    margin-bottom: 25px;

                    @include lg-mobile {
                        height: auto;
                    }
                }

                .stnd-cls-card-btn {
                    text-align: left;

                    @include lg-mobile {
                        text-align: center;
                    }
                }

            }
        }



    }
}