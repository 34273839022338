@import "@Assets/styles/Variables/Variable.scss";


.main-header-card {
    text-align: center;

    .mock-heading {
        font-weight: 700;
        font-size: 48px;
        line-height: 62px;
        text-align: center;
        color: #101828;

        @include lg-mobile {
            font-size: 24px;
            line-height: 35px;
        }
    }

    .moc-para {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #667085;
        padding-top: 10px;

        @include lg-mobile {
            font-size: 15px;
            line-height: 25px;
        }
    }
}


.select-your-subject-container {
    padding: 40px 60px;
    background: #FFFFFF;

    @include lg-mobile {
        padding: 30px 20px;
    }
img {
    max-width: 38px;
    
}

    .select-your-sbjct-cards-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;        
        gap: 15px;
        justify-content: flex-start;
    }

    .s-y-link-card{
        width: calc(25% - 30px);

        @include lg-mobile{
            width: 100%;
        }
    }
    .s-y-sbjct-card {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 24px;
        width: 100%;
        border: 1px solid #fff;

        img{
            width: 38px;
            height: 38px;
        }

      

        .subj-select-card-header{
            font-weight: 500;
            font-size: 15px;
            line-height: 150%;
            color: #000000;
        
            @include lg-mobile {
                font-size: 14px;
                margin-top: 12px;
            }
        }
    }

    .subject-bg-arrow-svg {
        display: none;
    }

    .active-subject-selcted {
        border: 1px solid #7F56D9;
        box-shadow: 0px 8px 16px rgba(127, 86, 217, 0.05);
        border-radius: 10px;

        .subject-arrow-svg {
            display: none;
        }

        .subject-bg-arrow-svg {
            display: block;
        }
    }

   

}
.lode-more-card {
    text-align: center;

    .load-more-btn {
        padding: 15px 28px;
        background: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
        border-radius: 8px;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;
        margin-top: 38px;

        @include lg-mobile {
            width: 200px;
            margin-top: 25px;
        }
    }
}