@import "@Assets/styles/Variables/Variable.scss";

.test-question-container {
  background: #F4F5F7;
  padding: 25px 80px;

  @include lg-mobile {
    padding: 15px 10px 40px 10px;
  }

  .test-qust-cards-container {
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px 0px 0px 0px;

    .test-qust-header {
      display: flex;

      .left-qust-header {
        border-bottom: 1px solid #eaecf0;
        border-right: 1px solid #eaecf0;
        padding: 25px 32px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        .t-qust-heading {
          font-weight: 600;
          font-size: 20px;
          line-height: 150%;
          color: #101828;
        }

        .t-qust-timer {
          background: #f9f5ff;
          border: 1px solid #eaecf0;
          border-radius: 5px;
          display: flex;
          align-items: center;
          padding: 15px 12px;

          .timer-img{
            margin-right: 5px;
          }

          .timer-span {
         
            font-weight: 600;
            font-size: 18px;
            line-height: 17px;
            text-align: right;
            color: #7f56d9;
            display: flex;
            align-items: center;
            margin-right: 10px;

            .my-timer-section{
              margin-right: 10px;
              font-weight: 500;
              font-size: 14px;
            }
          }

          .pause-svg {
            padding: 11px;
            background: #7f56d9;
            border: 1px solid #eaecf0;
            border-radius: 0px 5px 5px 0px;
            cursor: pointer;
          }
        }
      }

      .quest-palette-header {
        padding: 33px;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        color: #101828;
        border-bottom: 1px solid #eaecf0;
        width: 380px;

        @include lg-mobile {
          display: none;
        }
      }
    }

    .qust-palette-container{
      display: flex;

      @include lg-mobile{
        flex-direction: column;
      }
    }

    .test-question-card {
      display: flex;
      flex-direction: column;
      flex: 1;

      @include lg-mobile {
        flex-wrap: wrap;
        width: 100%;
      }

      .qust-container {
        flex: 1;
        border-right: 1px solid #eaecf0;
      }

  
    }

    .qust-mob-card {

      @include lg-mobile {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9;
        background: #fff;
        height: auto;
        display: none;
        padding: 15px 0;
      }
    }

    .isactive-card {
      @include lg-mobile {
        display: block;
      }

    }
    .qust-palette-card {
      width: 380px;
      height: calc(90vh - 50px);
      overflow: auto;

      @include lg-mobile {
        height: auto;
        width: 100%;
      }

      .instru-card {
        padding: 16px 20px;

        .inst-close-btn{
          display: flex;
          justify-content: flex-end;
         
         }

        .inst-heading {
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          color: #101828;
        }

        .instru-ul {
          .inst-li {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #101828;
            opacity: 0.9;
            margin: 15px 0;
          }

          .sub-inst-ul {
            padding-left: 25px;
          }
        }

        .response-qust-card {

          .rspn-opt-card {
            background: #FFFFFF;
            border: 1px solid rgba(16, 24, 40, 0.11);
            border-radius: 10px;
            padding: 11px;
            margin: 8px 0;
          }

          .active-opt-resp {
            background: #FAFBFF;
            border: 1px solid #EAECF0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .righttick-Svg {
              display: none;
            }

            .righttick-Svg {
              display: block;
            }

          }
        }
      }
    }

    .question-footer {
      display: flex;
      justify-content: flex-end;

      @include lg-mobile {
        flex-direction: column;
      }

      .footer-back-card {
        background: #ffffff;
        border-top: 1px solid #eaecf0;
        border-radius: 0px 0px 0px 10px;
        padding: 34px 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;

        .back-qust {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            margin-right: 11px;
          }
        }
      }

      .footer-left-card {
        background: #ffffff;
        border-top: 1px solid #eaecf0;
        border-radius: 0px 0px 0px 10px;
        padding: 34px 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        @include lg-mobile {
          border-top: 2px solid #eaecf0;
          padding: 25px;
        }
        .palette-a-card{
          display: none;

          @include lg-mobile{
            display: block;
            position: absolute;
            left: 44%;
            img{
              width: 50px;
              height: 50px;
            }
          }
          
        }

        .next-qust {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-left: auto;

          img {
            margin-left: 11px;
          }
        }

        .prev-qust {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            margin-right: 11px;
          }
        }
      }

   
    }
    .speed-test-right-footer{
      .speed-test-inst{
        width: 100%;
        justify-content: center;
      }
    }
    .footer-right-card {
      border-top: 1px solid #eaecf0;
      border-right: 1px solid #eaecf0;
      border-left: 1px solid #eaecf0;
      padding: 22px 19px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 380px;

      @include lg-mobile {
        border-top: 2px solid #eaecf0;
        width: 100%;

      }

      .qust-inst-card{
        display: flex;
        width: 100%;

        @include lg-mobile{
         flex-direction: column;
         width: 100%;
        }
      }

      .qust-a-btn {
        background: #ffffff;
        border: 1px solid #7f56d9;
        border-radius: 8px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #7f56d9;
        padding: 8px 15px;
        margin-right: 30px;

        @include lg-mobile{
          width: 100%;
          margin-right: 0;
          padding: 10px;
        }
      }

      .active-btn {
        background: rgba(127, 86, 217, 0.15);
      }
      
      

      .qust-inst-btn {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #7f56d9;
        border: 1px solid #7f56d9;
        border-radius: 8px;
        padding: 8px 20px;
        display: flex;
        

        img {
          margin-right: 10px;
        }

        @include lg-mobile{
          width: 100%;
          padding: 10px;
          justify-content: center;
          margin-top: 15px;
        }
      }

      .end-test-btn {
        background: linear-gradient(270deg, #986efa 27.07%, #d27bb8 155.73%);
        box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
        border-radius: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        padding: 10px 130px;
        margin-top: 20px;
        display: block;

        @include lg-mobile{
          margin-top: 12px;
          padding: 10px 100px;
          width: 100% !important;
          text-align: center;
        }
      }
    }
  }
}




/*---------------------dialog-scss-------------------------*/

.moc-test-unfinish-popup{
  width: 375px;

  @include lg-mobile{
    width: 100%;
  }
}
.mock-test-dialog-box{
  .p-dialog-header{
    display: none;
  }

 .p-dialog-content{
  border-radius: 6px;

  }
  

.pause-dialog-box {
  padding-top: 20px;

  .p-dlg-heading {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.2px;
    color: #101828;
    padding-bottom: 10px;

  }

  .p-dilg-para {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #101828;
  }

  .dialog-end-test-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.24px;
    color: #7F56D9;
    border: 1px solid #7F56D9;
    border-radius: 8px;
    padding: 12px 20px;
    margin-right: 20px;
    width: 145px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 10px;
    }
  }

  .resume-test-btn {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    background: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
    box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
    border-radius: 8px;
    padding: 12px 25px;
    width: 145px;
  }
}


.close-pallete-1 {
  display: none;

  @include lg-mobile {
    position: absolute;
    right: 15px;
    top: 13px;
    z-index: 3;
    display: block;
  }

}




}