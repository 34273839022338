.login-drop-down-prof-dash {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.dropdown-ui-1 {

    padding: 1rem;
    position: relative;
    text-decoration: none;
    transition-duration: 0.5s;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #101828;


    a {
        color: #101828;
        text-decoration: none;
        display: flex;
        align-items: center;

        .down-arrow-svg {
            img {
                margin-left: 10px;
            }
        }
    }
}





.dropdown-ui-container {
    background: #FFFFFF;
    border: 1px solid #DEE2E6;
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 8px;
    visibility: hidden;
    opacity: 0;
    min-width: 240px;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: 15px;
    left: -70%;
    display: none;
    z-index: 10;
    max-height: 320px;
    overflow-y: auto;

    .user-dropdown-li {
        padding: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.03em;
        color: #101828;
        border-bottom: 1px solid #D0D8E3;
        width: 100%;
    }

    .user-dropdown-li:hover {
        background: #7F56D9;

        a {
            color: #FFFFFF;
        }

    }
}

.dropdown-ui-1:hover>ul,
ul .dropdown-ui-1 .dropdown-ui-container:hover {
    visibility: visible;
    opacity: 1;
    display: block;
}