@import "@Assets/styles/Variables/Variable.scss";

.dash-header-sect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px #EAECF0;
    padding: 23px 60px;

    @include lg-mobile{
        padding: 20px;
    }


    .header-logo {
        img {
            width: 158px;
            height: 34px;
            margin-right: 35px;

        }

    }

    .notif-bell-span {
        background: #FAFAFA;
        border-radius: 35px;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
    }

    .user-name {

        background: #F9F5FF;
        border: 1px solid #7F56D9;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        width: 37px;
        height: 37px;
        border-radius: 50%;
        color: #7F56D9;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }
}