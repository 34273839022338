@import "@Assets/styles/Variables/Variable.scss";

.footer-section-container {
    padding: 50px 110px;
    background: #101828;

    @include lg-mobile {
        padding: 20px;
    }


    .footer-top-sec {
        display: flex;

        @include lg-mobile {
            flex-wrap: wrap;
        }


        .f-t-logo-card {
            margin-right: 30px;
            margin-top: 15px;


            .join-para {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #EAECF0;
                width: 300px;
                margin-top: 37px;

                @include lg-mobile {
                    margin: 25px 0 25px 18px;
                }
            }
        }

        .f-t-right-sec {
            display: flex;
            flex-wrap: wrap;

            .footer-list-heading {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #98A2B3;
                margin-left: 60px;

                @include lg-mobile{
                    margin-left: 28px;
                }

            }
        }

        .footer-ul-card {
            margin: 0 60px;

            @include lg-mobile {
                margin: 0 30px;
            }

            .footer-heading {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #EAECF0;
                    margin: 15px 0;
                }

                // li:first-child {
                //     color: #98A2B3;
                // }
            }
        }
    }

    .footer-bottom-sec {
        display: flex;
        justify-content: space-between;
        margin-top: 96px;

        @include lg-mobile {
            flex-wrap: wrap;
            margin-top: 50px;
            justify-content: center;
            margin-bottom: 30px;
        }

        .all-right-head {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #98A2B3;

            @include lg-mobile {
                text-align: center;
                margin-bottom: 15px;
            }
        }

        .social-media-icons {
            display: flex;
            align-items: center;

            @include lg-mobile {
                margin-top: 25px;
            }

            .icons-svg {
                margin: 0 12px;
            }
        }

    }
}