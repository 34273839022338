.validation-msg {
    color: red !important;
}

.section-heading {
    font-size: 24px;
    color: #0A2C3F;
    font-weight: 700;
}

.text-pri-blue-75 {
    color: rgba(10, 44, 63, 0.75);
}

.text-pri-blue {
    color: rgba(10, 44, 63, 1);
}

.text-pri-blue-45 {
    color: rgba(10, 44, 63, 0.45);
}

.fs14 {
    font-size: 14px;
}

.fs13 {
    font-size: 13px;
}

.bbt {
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(10, 44, 63, 0.15);
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Choose File';
    display: inline-block;
    background: #1480B7;
    color: #fff;

    &:hover {
        filter: brightness(95%);
    }

    padding: 12px 14px;
    border-radius: 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    font-weight: 700;
}

.section-container {
    background: #FFFFFF;
    border: 1px solid rgba(17, 13, 63, 0.08);
    box-shadow: 0px 44px 60px -20px rgba(8, 17, 49, 0.08);
    border-radius: 20px;
}

.profile-img {
    border: 5px solid #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);
    width: 72px;
    height: 72px;
    border-radius: 50%;
}

.vertical-divider {
    border-right: 1px solid #2F304324;
}

.ant-card {
    background: #FFFFFF!important;
    border: 1px solid #EAECF0!important;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)!important;
}


/* scrollbar */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius:16px;
    border: 1px solid rgba(0, 0, 0, .30);
    background-color: #F5F5F5;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color:#C1C1C1;
    border-radius:50px;
    &:hover { background-color:#986EFA; }
  }
  ::-webkit-scrollbar-button {display:none}
  *{
    scrollbar-face-color:#C1C1C1;
    scrollbar-shadow-color:#e2e2e2;
    scrollbar-track-color:#e2e2e2;
    scrollbar-arrow-color:#e2e2e2;
  }
  
 
  