@import "@Assets/styles/Variables/Variable.scss";

.user-profile-right-container {
  padding: 28px 322px 28px 65px;

  @include lg-mobile {
    padding: 20px;
  }

  .user-act-header {
    .user-name-card {
      background: #f9f5ff;
      border: 1px solid #7f56d9;
      width: 70px;
      height: 70px;
      font-weight: 500;
      font-size: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-feature-settings: "salt" on, "liga" off;
      color: #7f56d9;
      border-radius: 50%;
      text-transform: uppercase;

      @include lg-mobile {
        width: 65px;
        height: 65px;
        font-size: 22px;
        min-width: 65px;
        min-height: 65px;
      }
    }

    .usr-prf-heading {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #101828;
      padding-bottom: 5px;
      text-transform: capitalize;
    }

    .user-prf-para {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #667085;
    }

    .profile-strenght-bars-cards {
      margin: 8px 0;
      display: flex;

      .profile-strenght-bars {
        margin-right: 6px;
      }
    }
  }

  .user-profile-card {
    border: 1px solid #eaedef;
    border-radius: 12px;
    padding: 20px;
    margin: 28px 0;
    box-sizing: border-box;
  }

  .user-profile-btn {
    padding: 11px 24px;
    background: linear-gradient(270deg, #986efa 27.07%, #d27bb8 155.73%);
    box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    margin-top: 12px;
  }

  .user-profile-card-main-heading {
    font-weight: 600;
    font-size: 22px;
    line-height: 32px;
    color: #101828;
  }

  .user-profile-card-sub-heading {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #101828;
  }

  .mob-num {

    @include lg-mobile {
      margin-top: 20px;
    }
  }

  .add-detail-card {
    .add-detail-head {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #667085;
    }
  }

  .personal-detail-card {
    .user-p-c-para-1 {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: #667085;
      padding-bottom: 20px;
      border-bottom: 1px solid #eaecf0;
      text-transform: capitalize;
    }

    .u-p-c-para-2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: #667085;
    }

    .courses-prefered-mg {
      margin-right: 230px;
    }
  }




  .profile-input-card {
    margin-right: 150px;

    @include lg-mobile {
      margin-right: 10px;
    }

    .input-form-card {
      .form-label-log {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #101828;
        padding-top: 15px;
      }

      .password-box {
        display: flex;
        align-items: center;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        height: 45px;
        padding: 0 18px;
        margin-top: 10px;

        input {
          border: none;
          outline: none;
          width: 100%;
        }

        .show-psswd {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #667085;
        }
      }

      .input-form-control {
        background: #ffffff;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        width: 100%;
        padding: 14px 18px;
        margin-top: 10px;
        outline: none;
      }

      .select-form-control {
        background: #ffffff;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        margin-top: 10px;
      }

      .prime-deopdown {
        background: #ffffff;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        width: 100%;
        margin-top: 10px;

      }
    }

    .forget-pass-span {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #7F56D9;
      padding-top: 10px;
      float: right;
      cursor: pointer;
      text-decoration: none;
    }
  }



  .custom-select-card {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #667085;
    padding: 15px 14px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    width: 121px;
    text-align: center;
    cursor: pointer;
  }

  .active-custom-select {
    background: rgba(127, 86, 217, 0.15);
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    color: #7F56D9;
  }


















}



.course-prefernce-selectbutton {


  margin-top: 10px;




  .p-component {
    display: flex;
    justify-content: space-between;

    .p-buttonset .p-button:first-of-type {}

    .p-button {
      border-radius: 8px !important;
      border: 1px solid #D0D5DD;
      padding: 15px;
      width: 120px;
      box-shadow: none;

      .p-button-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #667085;
      }
    }

    .p-button:not(button):not(a):not(.p-disabled):active {
      background: rgba(127, 86, 217, 0.15);
      color: #7F56D9;
      border-color: rgba(127, 86, 217, 0.15);
    }

  }

  .p-buttonset .p-button:not(:last-child) {
    border-right: 1px solid #D0D5DD;
  }

  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: rgba(127, 86, 217, 0.15);
    color: #7F56D9;
    border-color: rgba(127, 86, 217, 0.15);

    .p-button-label {
      color: #7F56D9;
    }
  }

  .p-selectbutton .p-button.p-highlight {
    background: rgba(127, 86, 217, 0.15);
    color: #7F56D9;
    border-color: rgba(127, 86, 217, 0.15);

    .p-button-label {
      color: #7F56D9;
    }
  }

  .p-selectbutton .p-button.p-highlight:hover {
    background: rgba(127, 86, 217, 0.15) !important;
    border-color: rgba(127, 86, 217, 0.15) !important;
    color: #7F56D9 !important;
  }
}

.Dob-calendar {
  width: 100%;
  margin-top: 10px;

  .p-button {
    color: #7F56D9;
    background: #FFFFFF;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #ced4da;
    border-left: none;
  }

  .p-button:enabled:hover,
  .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #FFFFFF;
    color: #7F56D9;
    border-color: #ced4da;
  }

  .p-button:focus {
    box-shadow: none;
  }

  .p-inputtext {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    width: 100%;
  }

 
  .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #ced4da;
}

  .p-inputtext:enabled:hover {
    border-color: #ced4da;
}

}

.mandiatory-star{
  color: red;
}
