@import "@Assets/styles/Variables/Variable.scss";

.contact-us-section {
    background: #F9F5FF;
    padding: 40px 80px;

    @include lg-mobile {
        padding: 15px;
    }

    .c-u-s-head {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.03em;
        color: #667085;

        .active-span {
            color: #986EFA;
        }
    }

    .c-u-heading {
        font-weight: 700;
        font-size: 48px;
        line-height: 62px;
        color: #101828;
        margin-top: 30px;
        margin-bottom: 20px;

        @include lg-mobile {
            font-size: 30px;
            text-align: center;
            margin: 20px auto 16px auto;
            line-height: 30px;
        }
    }

    .c-u-card {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        @include lg-mobile {
            flex-direction: column;
        }

        .c-u-card-left {
            width: 42%;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #667085;

            @include lg-mobile {
                width: 100%;
                text-align: center;
                margin-bottom: 35px;
            }


        }

        .c-u-card-right {
            width: 45%;

            @include lg-mobile {
                width: 100%;
                text-align: center;
            }

            .marg-div {
                margin-top: 50px;
            }
        }

        .c-u-details {
            display: flex;
            gap: 30px;

            @include lg-mobile {
                flex-direction: column;
                align-items: center;
            }

            .c-u-d-sub-card {
                display: flex;
                gap: 14px;

                .c-u-tel-email {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #667085;
                    margin-bottom: 8px;

                    @include lg-mobile {
                        text-align: left;
                    }

                    
                }

            }
        }
    }
}


// input::-webkit-input-placeholder::after{
//      content: 'this';
//     color: red;
// }

.g-i-t-head-mob {
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    line-height: 40px;
    color: #101828;
    margin-top: 30px;
}

.get-in-touch-sec {
    padding: 60px 60px 70px 60px;
    display: flex;
    justify-content: space-between;

    @include lg-mobile {
        flex-direction: column;
        padding: 20px 20px 60px 20px;
    }


    .g-i-t-left-card {
        width: 48%;

        @include lg-mobile {
            width: 100%;
            order: 2;
        }

        .g-i-t-head {
            font-weight: 700;
            font-size: 48px;
            line-height: 62px;
            color: #101828;
            margin-bottom: 20px;
            margin-top: 15px;

            @include lg-mobile {

                display: none;
            }
        }



        .g-i-t-para {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #667085;
            margin-bottom: 26px;
        }

        .n-e-card {
            display: flex;
            gap: 24px;

            @include lg-mobile {
                flex-direction: column;
                gap: 0;

                br{
                    display: none;
                }
              }
        }

        .contact-input {
            width: 100%;
            margin: 14px 0;

        }

        .send-mail-btn {
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #FFFFFF;
            background: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
            border-radius: 8px;
            padding: 10px 40px;
            margin-top: 16px;
        }

    }

    .g-i-t-right-card {

        @include lg-mobile {
            order: 1;
        }

        img {
            width: 100%;

            @include lg-mobile {
                margin-bottom: 35px;
            }
        }
    }
}