@import "@Assets/styles/Variables/Variable.scss";

.mob-header-container {
   display: none;

    @include lg-mobile{
        display: block;
        padding: 23px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background-color: #FFFF;
        z-index: 99;

        
    }


  

    .search-container {
        position: relative;
        display: inline-block;
        margin: 4px 2px;
        height: 50px;
        min-width: 50px;
        vertical-align: bottom;

        .button {

            display: inline-block;
            margin: 4px 2px;
            font-size: 14px;
            padding-left: 32px;
            padding-right: 32px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: white;
            text-decoration: none;
            cursor: pointer;
            -moz-user-select: none;
            -khtml-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    
        .button:hover {
            transition-duration: 0.4s;
            -moz-transition-duration: 0.4s;
            -webkit-transition-duration: 0.4s;
            -o-transition-duration: 0.4s;
            background-color: white;
            color: black;
        }

        .mglass {
            display: inline-block;
            pointer-events: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background: #F9F5FF;
            border-radius: 50%;
            // -webkit-transform: rotate(-45deg);
            // -moz-transform: rotate(-45deg);
            // -o-transform: rotate(-45deg);
            // -ms-transform: rotate(-45deg);
        }
    
        .searchbutton {
            position: absolute;
            font-size: 22px;
            width: 96%;
            margin: 0;
            padding: 0;
        }
    
        .search:focus+.searchbutton {
            transition-duration: 0.4s;
            -moz-transition-duration: 0.4s;
            -webkit-transition-duration: 0.4s;
            -o-transition-duration: 0.4s;
            background-color: white;
            color: black;
        }
    
        .search {
            position: absolute;
            left: 49px;
            /* Button width-1px (Not 50px/100% because that will sometimes show a 1px line between the search box and button) */
            background-color: white;
            outline: none;
            border: none;
            padding: 0;
            width: 0%;
            height: 100%;
            z-index: 10;
            transition-duration: 0.4s;
            -moz-transition-duration: 0.4s;
            -webkit-transition-duration: 0.4s;
            -o-transition-duration: 0.4s;
            border-radius: 35px;
        }
    
        .search:focus {
            @include lg-mobile{
              width: 280px;
            /* Bar width+1px */
            padding: 0 16px 0 16px;
            left: 0;
            border: 1px solid #7F56D9;
            }
    
            @include tablet{
                width: 700px;
            }
        }
    }

   
    
    .search-container-mob {
    
        border: 2px solid var(--clr-primary);
        display: flex;
        border-radius: 100px;
        overflow: hidden;
        font-size: 1.25em;
        position: relative;
        width:  60px;
        height:  60px;
        // margin-left: auto;
        padding: 3px;
        
        @media (prefers-reduced-motion: no-preference) {
            transition: width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
        }

        .p-inputtext , .search-mob {
            border: 0;
            padding: .25em 1em;
            flex-grow: 1;
            outline: 0;
            z-index: 2;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            background: transparent;
            opacity: 0;
            cursor: pointer;
          }
          
          .search__submit {
            font-size: 1.5rem;
            margin-left: auto;
            background: 0;
            border: 0;
            cursor: pointer;
            border-radius: 50%;
            transition: background 200ms ease-out;
            width: calc(var(--size) - 10px);
            height: calc(var(--size) - 10px);
            color: var(--clr-body);
          }
      }   
      .search-container-mob:focus-within {
        // box-shadow: 0 0 5px var(--clr-primary);
        width: 400px;
        
        .p-inputtext  , .search-mob{
          opacity: 1;
          z-index: initial;
          cursor: initial;
          width: calc(100% -  60px);
        }
        
        .search__submit {
          background: #FFFF;
          color: white;
          box-shadow: 0 0 10px rgba(0,0,0,.15);
          // transition: transform 500ms ease-out;
          
          &:hover,
          &:focus {
            outline: 0;
            // transform: rotate(1turn);
            box-shadow: 0 0 10px rgba(0,0,0,.65);
          }
        }
      }


    .search-container-mob-vers{
        display: block;
        position: relative;
        width:  60px;
        height:  60px;
        overflow: hidden;
        z-index: 10;
        transition-duration: 0.4s;
        -moz-transition-duration: 0.4s;
        -webkit-transition-duration: 0.4s;
        -o-transition-duration: 0.4s;
        border-radius: 35px;
        position: absolute;
        top: 20px;
        right: 0;
        left: 22px;
           
        // @media (prefers-reduced-motion: no-preference) {
        //     transition: width 450ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
        // }

        .mglass {
            display: inline-block;
            pointer-events: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background: #F9F5FF;
            border-radius: 50%;
            margin: 4px;
        }

        .search-mob{
            position: absolute;
            top: 0%;
            bottom: 0%;
            left: 0%;
            width: 100%;
            background: transparent;
            opacity: 0;
            cursor: pointer;
            margin: 2px 4px;
            z-index: 10;

            .p-inputtext{
                border: none;
                outline: none;
                border-radius: 35px;
                
            }

            .p-inputtext:enabled:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: none;
                border-color: none;
            }
        }

        &:focus-within {
            width: calc(100% - 100px);
            overflow: visible;
            border: 1px solid #7F56D9;
            border-radius: 35px;

            .search-mob{
              opacity: 1;

            }
        }




    }























    .mob-header-logo {
        z-index: 1;

        img {
            width: 150px;
            height: auto;
        }

    }

    .select-menu {
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

}


.overlay-header {
    display: none;

    @include lg-mobile{
        display: block;
        position: relative;

    }


}

.overlay-card {
    position: fixed;
    background: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    min-height: 500px;
    overflow-y: auto;


    .user-name-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 23px;
        border-bottom: 1px solid #CDCDCD;
    }

    .user-letter {
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #F9F5FF;
        border: 1px solid #7F56D9;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #7F56D9;
        margin-right: 15px;
        text-transform: uppercase;

    }

    .user-name {
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        text-transform: capitalize;
    }

    .wel-come-tag {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        padding-bottom: 15px;
    }

    .log-in{
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;        
        color: #7F56D9;
    }

    .overlay-menu-ul {
        list-style: none;


        .over-ul-li {
            border-bottom: 0.8px solid #CDCDCD;


            .over-link-a {
                font-weight: 550;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.03em;
                color: #101828;
                text-decoration: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 22px;
            }

            .active-a-link {

                background: #f4f5f7;
                color: #7F56D9;
            }

            .logout-over-link-a {
                font-weight: 550;
                font-size: 18px;
                line-height: 22px;
                letter-spacing: 0.03em;
                color: #101828;
                text-decoration: none;
                padding: 20px 22px;
                display: flex;
                align-items: center;

                .logout-svg-img {
                    margin-right: 12px;
                }

            }

            .dropdown-ul {
                list-style: none;

                li {

                    border-bottom: 0.8px solid #CDCDCD;
                    padding: 20px 22px;

                    a {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        letter-spacing: 0.03em;
                        color: #101828;
                        text-decoration: none;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                    }

                }


            }
        }

    }
}




/*--------------------sidemenu--------------------------*/