@import "@Assets/styles/Variables/Variable.scss";
.online-platform-container {
    background: linear-gradient(0deg, rgba(223, 225, 238, 0.9), rgba(223, 225, 238, 0.9));
    // padding: 30px 40px;

    @include lg-mobile {
        padding: 0px;
        width: 100%;
    }


    .platform-main-content {
     

        .online-platform-left-content {
            margin-right: 90px;

            @include lg-mobile {
                margin-right: 0;
                margin-bottom: 20px;
            }
             .plt-h1-head-mob{
                display: none;

                @include lg-mobile{
                    font-size: 26px;
                    text-align: center;
                    line-height: 38px;
                    display: block;
                    font-weight: 700;
                }
                
                
             } 
            
            .plt-h1-head {
                font-size: 64px;
                font-weight: 700;
                color: #101828;
                @include lg-mobile{
                    display: none;
                }

            }
            
            .plt-head-span {

                color: transparent;
                background: linear-gradient(50deg, #D27BB8 5%, #986EFA 80%);
                -webkit-background-clip: text;

            }

            .plt-para {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #667085;
                padding: 0px 0 30px 0;
                @include lg-mobile{
                    text-align: center;
                    font-size: 14px;
                    line-height: 25px;
                }
                


                @include lg-mobile {
                    font-size: 18px;
                    padding-top: 15px;

                    br {
                        display: none;
                    }
                }
            }

            .mock-btn-card{
                @include lg-mobile{
                    text-align: center;
                }
               
            }

         

        }
       

        .online-platform-right-img {
            position: relative;
            display: flex;
    justify-content: center;
            .banner-img{
               
                max-height: 400px;
                max-width: 100%;
             } 

             .take-mock-test-btn {
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                color: #FFFFFF;
                padding: 16px 28px;
                background: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
                box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
                border-radius: 8px;
                position: absolute;
                bottom: 80%;
                left: 4%;
                z-index: 999;
    display: none;
                @include lg-mobile {
                    width: 180px;
                    font-size: 16px;
                    align-self: center;
                }
            }
          
        }

    }

    .pagenation-card {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            margin: 0 6px;
            cursor: pointer;
        }

        span:first-child {
            margin-top: 8px;
        }
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        background-color: #F9F5FF;
        width: 12px;
        height: 12px;
        transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
        border-radius: 50%;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        // background-color: linear-gradient(270deg, #986EFA 27.07%, #D27BB8 155.73%);
        background-color: #986EFA;
        box-shadow: 0px 4px 8px rgba(152, 110, 250, 0.25);
        border-radius: 8px;
        width: 48px;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
        background: #986EFA;
    }

    .p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next{
        display: none;
    }

    .pointer-cursor{
        cursor: pointer;
    }

/* */
.p-carousel {
    .p-carousel-content {
        position: relative;
        .p-carousel-indicators {
            position: absolute;
    bottom: 0;
    width: 100%;
        }
    }
}



}