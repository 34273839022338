@import "@Assets/styles/Variables/Variable.scss";

.main-header-card {
    text-align: center;

    .mock-heading {
        font-weight: 700;
        font-size: 48px;
        line-height: 62px;
        text-align: center;
        color: #101828;

        @include lg-mobile {
            font-size: 24px;
            line-height: 35px;
        }
    }

    .moc-para {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #667085;
        padding-top: 10px;

        @include lg-mobile {
            font-size: 15px;
            line-height: 25px;
        }
    }
}

.why-choose-us-container {
    padding: 40px 60px;
    background: #F9F5FF;

    @include lg-mobile {
        padding: 30px 20px;
    }


    .why-choose-us-cards-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        justify-content: flex-start;

        .why-choose-us-cards {
            background: #FFFFFF;
            box-shadow: 0px 4px 8px rgba(16, 24, 40, 0.05);
            border-radius: 10px;
            padding: 30px 25px;
            width: calc(33.333% - 50px);
            margin: 22px;

            @include lg-mobile{
                width: 100%;
            }

            .w-c-u-card-svg {
                margin-bottom: 15px;
            }



        }
    }
}