@import "@Assets/styles/Variables/Variable.scss";

.mock-test-listining-container {
    padding: 20px 10px 20px 30px;
    background: #EFEBF5;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 60px;

    @include lg-mobile {
        margin: 0 20px;
        padding: 20px 15px;
    }

    .m-t-l-tabs {
        display: flex;

        .m-t-l-span {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.03em;
            color: #667085;
            margin: 3px 5px 0 5px;

            @include lg-mobile {
                margin-left: 5px;
            }
        }

        .active-span {
            color: #7F56D9;
        }
    }

    .m-t-l-header {
        font-weight: 700;
        font-size: 40px;
        line-height: 60px;
        color: #000000;
        margin: 27px 0 0 30px;

        @include lg-mobile {
            font-size: 30px;
            margin: 5px 0 0 20px;
            line-height: 35px;
            text-align: center;
            margin-top: 27px;

            br {
                display: none;
            }
        }


        .m-t-l-head-span {
            background: linear-gradient(180deg, #D27BB8 -39.5%, #986EFA 116%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;

        }

    }

    .m-t-l-img-card {
        margin-right: 60px;
        display: flex;
        align-items: center;
        img{
            max-width: 450px;
            

            @include lg-mobile{
              width: 100%;
              height: auto;
            }
        }
        @include lg-mobile {
            margin-top: 40px;
            margin-right: 0;
            text-align: center;

        }

    }
}