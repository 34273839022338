@import "@Assets/styles/Variables/Variable.scss";
.most-popular-series-container {
    padding: 0 60px 35px 60px;

    @include lg-mobile {
        padding: 20px 30px;
    }

    .most-popular-cards-container {
        display: flex;
        flex-wrap: wrap;
        align-items: inherit;
        margin-top: 18px;
        gap: 18px ;

        @include lg-mobile {
            flex-wrap: wrap;
        }

        .m-p-card {
            background: #FFFFFF;
            border-radius: 8px;
            padding: 16px;
            width: calc(25% - 20px);

            @include lg-mobile {
                width: 100%;
                margin: 10px 0;


            }

            @include tablet{
                width: calc(50% - 50px);

            }

            .subject-card {
                width: 100%;
                height: auto;
                margin-bottom: 36px;
                position: relative;
                z-index: 1;

            

            }
            .subject-img{
                width: 100%;
                height: auto;
                max-height: 100%;
                max-width: 100%;

            }

            .subject-title-card {
                position: absolute;
                top: 38%;
                left: 0%;
                right: 0%;
                background: rgba(255, 255, 255, 0.85);
                border-radius: 6px;
                padding: 6px 8px;
                z-index: 10;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #101828;
                text-align: center;

            }
            .subject-test-levl-card{
                position: absolute;
                top: 6%;
                right: 4%;
                background: rgba(255, 255, 255, 0.85);
                border-radius: 6px;
                padding: 5px 10px;
                z-index: 10;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #101828;

            }


            .subject-test-descrip{
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                text-align: center;     
                color: #101828;
                margin-bottom: 15px;
                word-wrap: break-word;
                -webkit-line-clamp: 2;
    -webkit-box-orient: vertical!important;
    display: -webkit-box!important;
    overflow: hidden!important;
    min-height: 45px;
    @include lg-mobile {
        min-height: 10px;
    }
            }
        }



    }
}

.others-test-cards-container {
    display: flex;
    align-items: center;
    margin-top: 38px;

 
}

.othr-test-card:last-child {
    margin-right: 0;
}

.othr-test-card {
    width: 312px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-right: 28px;
    

    .o-t-c-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #EAECF0;
        padding: 16px 0;
        position: relative;
.test-card-imgs {
    padding-left: 16px;
    padding-right: 16px;
    height: 200px;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    img {
        //width: 100%;
        max-height: 100%;
        max-width: 100%;
    }
}
        .o-t-c-heading {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            color: #101828;
            padding-top: 16px;
            padding-bottom: 6px;
            padding-left: 16px;
            padding-right: 16px;

        }

        .o-t-c-expires-date {
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            color: #667085;

        }
        .subject-title-card {
            position: absolute;
            top: 8%;
            right: 8%;
            background: rgba(255, 255, 255, 0.85);
            border-radius: 6px;
            padding: 6px 8px;
            z-index: 10;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #101828;
            text-align: center;

        }

    }


    .question-paper {
        padding: 4px 16px;
        border-bottom: 1px solid #EAECF0;

        .qust-papr-head {
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            color: #101828;
        }

        .duration-marks-card {
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            color: #667085;

        }

    }
}


.skeleton-card{
    width: calc(25% - 20px);

    @include lg-mobile {
        width: 100%;
    } 
}